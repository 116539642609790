import { appConfig } from "../app";
import { BlockChainHelpers } from "./chain";

export class ApiHelpers {
  static configuration = {
    host: appConfig.backendController,
  };

  public static async get(url: string) {
    try {
      const json = await fetch(url);
      return await json.json();
    } catch (error) {
      return false;
    }
  }

  public static async post(url: string, data: any) {
    try {
      const json = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return await json.json();
    } catch (error) {
      return false;
    }
  }

  public static async uploadImages(formData: FormData) {
    try {
      const json = await fetch(this.configuration.host + "/upload-images", {
        method: "POST",
        body: formData,
      });
      return await json.json();
    } catch (error) {
      return false;
    }
  }

  public static async saveSoftNFTs(nfts: any) {
    return await ApiHelpers.post(this.configuration.host + "/soft-mint/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16), nfts);
  }

  public static async getSoftNFTs(owner: string) {
    return await ApiHelpers.get(this.configuration.host + "/soft-nfts/" + owner + "/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16));
  }

  public static async lastMintedNFTs() {
    return await ApiHelpers.get(this.configuration.host + "/last-mints/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16));
  }

  public static async lastCollections() {
    return await ApiHelpers.get(this.configuration.host + "/last-collections/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16));
  }

  public static async getUniversalBid(collection: string, id: number) {
    return await ApiHelpers.get(
      this.configuration.host + "/universal-bids/bid/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16) + "/" + collection + "/" + id
    );
  }

  public static async allCollections() {
    return await ApiHelpers.get(this.configuration.host + "/all-collections/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16));
  }

  public static async collection(address: string) {
    return await ApiHelpers.get(this.configuration.host + "/collection/" + address + "/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16));
  }

  public static async updateCollectionImage(image: string, address: string, loginHash: string, network: number) {
    return await ApiHelpers.post(this.configuration.host + "/update-collection-image/", { image, address, loginHash, network });
  }

  public static async getDistributions() {
    return await ApiHelpers.get(this.configuration.host + "/universal-distributions/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16));
  }

  public static async getStakingPoolRegisters(pool: string) {
    return await ApiHelpers.get(
      this.configuration.host + "/staking-pool-registers/" + pool + "/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16)
    );
  }

  public static async updateCollection(
    image: string,
    address: string,
    aboutOwner: string,
    aboutProject: any,
    projectLinks: any,
    gallery: string[],
    loginHash: string
  ) {
    return await ApiHelpers.post(this.configuration.host + "/update-collection", {
      image,
      address,
      aboutOwner,
      aboutProject,
      projectLinks,
      gallery,
      network: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
      loginHash,
    });
  }

  public static async preloadMintedNFTs(nfts: number[]) {
    return await ApiHelpers.post(this.configuration.host + "/preload-universalNFT/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16), {
      nfts,
    });
  }

  public static async preloadManyExternalNFTs(nfts: { _collection: string; _nftID: number }[]) {
    console.log(nfts);
    return await ApiHelpers.post(this.configuration.host + "/preload-external-nfts/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16), {
      nfts,
    });
  }

  public static async getUniversalBattlesValidNFTs() {
    return await ApiHelpers.get(this.configuration.host + "/universal-battles/valid-nfts");
  }

  public static async getUniversalBattleParty() {
    return await ApiHelpers.get(this.configuration.host + "/universal-battles/party");
  }

  public static async getPartyMemberBalanceHistory(address: string) {
    return await ApiHelpers.get(this.configuration.host + "/universal-battles/balance-history/" + address);
  }

  public static async getPartyMemberTeamHistory(address: string) {
    return await ApiHelpers.get(this.configuration.host + "/universal-battles/team-history/" + address);
  }

  public static async getUniversalBattlePartyMember(address: string) {
    return await ApiHelpers.get(this.configuration.host + "/universal-battles/party-member/" + address);
  }

  public static async getUniversalBids() {
    return await ApiHelpers.get(this.configuration.host + "/universal-bids/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16));
  }

  public static async getUniversalMiniBids() {
    return await ApiHelpers.get(this.configuration.host + "/universal-bids/mini-bids/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16));
  }

  public static async getUniversalMiniBid(id: number) {
    return await ApiHelpers.get(
      this.configuration.host + "/universal-bids/mini-bid/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16) + "/" + id
    );
  }

  public static async changeTeamMember(position: number, newMember: any, hash: string) {
    return await ApiHelpers.post(this.configuration.host + "/universal-battles/change-team", {
      hash,
      position,
      newMember,
      network: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
    });
  }

  public static async startFight(amount: number, opponent: string, hash: string) {
    return await ApiHelpers.post(this.configuration.host + "/universal-battles/start-battle", {
      hash,
      amount,
      opponent,
      network: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
    });
  }

  public static async claimBattleRewards(hash: string) {
    return await ApiHelpers.post(this.configuration.host + "/universal-battles/claim-battle", {
      hash,
      network: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
    });
  }

  public static async attackOnBattle(option: number, hash: string) {
    return await ApiHelpers.post(this.configuration.host + "/universal-battles/attack", {
      hash,
      option,
      network: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
    });
  }

  public static async getStakingPools() {
    return await ApiHelpers.get(
      this.configuration.host + "/universal-staking/staking-pools/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16)
    );
  }

  public static async getStakingPool(address: string) {
    return await ApiHelpers.get(
      this.configuration.host + "/universal-staking/staking-pool/" + address + "/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16)
    );
  }

  public static async preloadExternalNFTs(collection: string, nfts: number[]) {
    return await ApiHelpers.post(
      this.configuration.host + "/preload-collection-nfts/" + collection + "/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16),
      { nfts, force: false }
    );
  }

  public static async getPremintedNFT(collection: string, token: number, loginHash: string) {
    return await ApiHelpers.get(
      this.configuration.host +
        "/pre-minted-nft/" +
        collection +
        "/" +
        token +
        "/" +
        loginHash +
        "/" +
        parseInt(BlockChainHelpers.getAppChain().chainId, 16)
    );
  }

  public static async collectionsByOwner(owner: string) {
    return await ApiHelpers.get(
      this.configuration.host + "/collections-by-owner/" + owner + "/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16)
    );
  }

  public static async softCreatedCollection(image: string, name: string, symbol: string, baseUri: string, token: string, address: string) {
    return await ApiHelpers.post(this.configuration.host + "/new-collection/" + parseInt(BlockChainHelpers.getAppChain().chainId, 16), {
      image,
      name,
      symbol,
      baseUri,
      token,
      address,
    });
  }
}
