import Web3 from "web3";
import EventEmitter from "events";

import { NavWindow } from "../components/types";
import { BlockChainState } from "../storage/state/blockChain/state";
import { ContractsState } from "../storage/state/contracts/state";
import { ABINetworkData, ContractData } from "./contracts";
import { BlockChainHelpers } from "./helpers/chain";
import { appConfig, AppErrorCode, Contract, posibleContractAddress, ULTRA_ERRORS } from "./app";
import { UtilsHelpers } from "./helpers/utils";
import { Token } from "./contracts/token";
import { UniversalFactory } from "./contracts/universalFactory";
import { UniversalNFTGeneral } from "./contracts/universalNFTGeneral";
import { UniversalStaking } from "./contracts/universalStaking";
import { UniversalMinter } from "./contracts/universalMinter";
import { UniversalQueries } from "./contracts/universalQueries";
import { BattlesMiniGames } from "./contracts/battleMiniGames";
import { Bidding } from "./contracts/bidding";
import { MiniBidding } from "./contracts/miniBidding";
import { UniversalDistributor } from "./contracts/distributor";
import { LPStaking } from "./contracts/LPStaking";

export interface BlockChainData {
  accounts: string[];
  chainId: number | null;
}

export interface RelatedContracts {
  [Contract.TOKEN]: any;
  [Contract.UNIVERSAL_FACTORY]: any;
  [Contract.UNIVERSAL_GENERAL]: any;
  [Contract.UNIVERSAL_STAKING_FACTORY]: any;
  [Contract.UNIVERSAL_MINTER]: any;
  [Contract.UNIVERSAL_QUERIES]: any;
  [Contract.UNIVERSAL_BIDDING]: any;
  [Contract.BATTLE_MINI_GAMES]: any;
  [Contract.UNIVERSAL_MINI_BIDDING]: any;
  [Contract.DISTRIBUTOR]: any;
  [Contract.LP_STAKING]: any;
}

export interface RelatedContractsInstances {
  [Contract.TOKEN]: Token | null;
  [Contract.UNIVERSAL_FACTORY]: UniversalFactory | null;
  [Contract.UNIVERSAL_GENERAL]: UniversalNFTGeneral | null;
  [Contract.UNIVERSAL_STAKING_FACTORY]: UniversalStaking | null;
  [Contract.UNIVERSAL_MINTER]: UniversalMinter | null;
  [Contract.UNIVERSAL_QUERIES]: UniversalQueries | null;
  [Contract.UNIVERSAL_BIDDING]: Bidding | null;
  [Contract.BATTLE_MINI_GAMES]: BattlesMiniGames | null;
  [Contract.UNIVERSAL_MINI_BIDDING]: MiniBidding | null;
  [Contract.DISTRIBUTOR]: UniversalDistributor | null;
  [Contract.LP_STAKING]: LPStaking | null;
}

export enum BlockChainEvent {
  LOAD_CONTRACT = "load-contract",
  LOAD_CONTRACT_ERROR = "load-contract-error",
  END_CONTRACT_LOADING = "end-contract-loading",
  CHANGE_NETWORK = "change-network",
}

export enum BlockChainErrorEvent {}

export class SmartContract {}

export class BlockChain {
  private _accounts: string[] = [];
  private _provider: Web3 | null = null;
  private _chainId: number | null = null;
  private _contractsData: ContractsState | null = null;
  private _contractKeys: string[] = [];
  public _web3: Web3 | null = null;
  public buildTypes: string[] | null = null;
  public buildModels: string[] | null = null;

  private _contracts: RelatedContracts = {
    [Contract.TOKEN]: null,
    [Contract.UNIVERSAL_FACTORY]: null,
    [Contract.UNIVERSAL_GENERAL]: null,
    [Contract.UNIVERSAL_STAKING_FACTORY]: null,
    [Contract.UNIVERSAL_MINTER]: null,
    [Contract.UNIVERSAL_QUERIES]: null,
    [Contract.UNIVERSAL_BIDDING]: null,
    [Contract.BATTLE_MINI_GAMES]: null,
    [Contract.UNIVERSAL_MINI_BIDDING]: null,
    [Contract.DISTRIBUTOR]: null,
    [Contract.LP_STAKING]: null,
  };

  private _contractsInstance: RelatedContractsInstances = {
    [Contract.TOKEN]: null,
    [Contract.UNIVERSAL_FACTORY]: null,
    [Contract.UNIVERSAL_GENERAL]: null,
    [Contract.UNIVERSAL_STAKING_FACTORY]: null,
    [Contract.UNIVERSAL_MINTER]: null,
    [Contract.UNIVERSAL_BIDDING]: null,
    [Contract.UNIVERSAL_QUERIES]: null,
    [Contract.BATTLE_MINI_GAMES]: null,
    [Contract.UNIVERSAL_MINI_BIDDING]: null,
    [Contract.DISTRIBUTOR]: null,
    [Contract.LP_STAKING]: null,
  };

  // Subscriptions
  principalListener: EventEmitter = new EventEmitter();

  /* -------------------------------------------------------------------------- */
  /*                           ANCHOR Contract Loading                          */
  /* -------------------------------------------------------------------------- */

  async loadBlockChainData(contractsData: ContractsState, callback?: (err: AppErrorCode | null, blockChain?: BlockChain) => void) {
    if (this._contractsStateIsValid(contractsData)) {
      if (await BlockChainHelpers.loadWeb3()) {
        this._provider = BlockChainHelpers.getProvider();
        UtilsHelpers.debugger("Web3 is loaded.");

        if (!!this._provider) {
          UtilsHelpers.debugger("Provider is loaded.");

          let error: AppErrorCode | null = null;

          this._contractsData = contractsData;
          this._accounts = await this._provider.eth.requestAccounts();
          this._chainId = await this._provider.eth.getChainId();
          this._web3 = new Web3((window as NavWindow).ethereum);
          this._contractKeys = Object.keys(this._contractsData);

          UtilsHelpers.debugger("BlockChain Connection\n  Selected Account: " + this.selectedAccount + "\n   Chain ID: " + this._chainId);

          // Load all contracts

          if (this._contractKeys.length > 0) {
            for (let i = 0; i < this._contractKeys.length; i++) {
              let contractName: Contract = this._contractKeys[i] as Contract;

              UtilsHelpers.debugger("Search contract data (" + contractName + ").");

              if (this._contractsData[contractName]) {
                UtilsHelpers.debugger("Loading contract (" + this._contractKeys[i] + ")");

                let contractData = this._contractsData[this._contractKeys[i] as Contract];

                if (contractData) {
                  let contractLoading: AppErrorCode | null = await this._loadContract(contractData);

                  if (contractLoading !== null) {
                    error = contractLoading;

                    UtilsHelpers.debugger("Contract cannot be loading (" + error + ").");

                    break;
                  } else {
                    UtilsHelpers.debugger("Contract is loaded (" + contractData.contract + ")");
                  }
                } else UtilsHelpers.debugger("Contract data is not valid.");
              }
            }
          } else {
            UtilsHelpers.debugger("There are no contracts to load.");
          }

          if (error === null) {
            this.principalListener.emit(BlockChainEvent.END_CONTRACT_LOADING);
          }

          if (callback) callback(error, this);
        } else {
          UtilsHelpers.debugger("Invalid provider");
          if (callback) callback(AppErrorCode.INVALID_PROVIDER, this);
        }
      } else {
        UtilsHelpers.debugger("Web3 can not be loaded.");
        if (callback) callback(AppErrorCode.INVALID_PROVIDER);
      }
    } else {
      UtilsHelpers.debugger("Contracts state is not valid..");
      if (callback) callback(AppErrorCode.INVALID_CONTRACT_LOADING);
    }

    return this;
  }

  private async _loadContract(contractData: ContractData): Promise<AppErrorCode | null> {
    let error: AppErrorCode | null = AppErrorCode.INVALID_CONTRACT_LOADING;

    const modeContracts = posibleContractAddress[appConfig.mode];

    const contractsAddress = modeContracts ? modeContracts[contractData.contract] : null;

    if (this._contractDataIsValid(contractData)) {
      UtilsHelpers.debugger("Contract data is valid.");

      if (modeContracts === null || (modeContracts !== null && !contractsAddress)) {
        UtilsHelpers.debugger("Use data network");
        let network = await this._networkIsValidInContract(contractData);

        if (network && this._web3) {
          this._contracts[contractData.contract] = (await new this._web3.eth.Contract(contractData.data?.abi, network)) as any;

          UtilsHelpers.debugger("Load contract (" + contractData.contract + " - " + network + ")");

          if (!!this._contracts[contractData.contract]) {
            this._loadContractInstance(this._contracts[contractData.contract], contractData.contract);
            this.principalListener.emit(BlockChainEvent.LOAD_CONTRACT, contractData.contract);
            error = null;
          }
        } else {
          UtilsHelpers.debugger("You are in incorrect network.");
          this.principalListener.emit(AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK, null);
          error = AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK;
        }
      } else if (contractsAddress) {
        UtilsHelpers.debugger("Use static network.");

        if (this._web3) {
          this._contracts[contractData.contract] = (await new this._web3.eth.Contract(contractData.data?.abi, contractsAddress)) as any;

          UtilsHelpers.debugger("Load contract (" + contractData.contract + " - " + contractsAddress + ")");

          if (!!this._contracts[contractData.contract]) {
            this._loadContractInstance(this._contracts[contractData.contract], contractData.contract);
            this.principalListener.emit(BlockChainEvent.LOAD_CONTRACT, contractData.contract);
            error = null;
          }
        } else {
          UtilsHelpers.debugger("You are in incorrect network.");
          this.principalListener.emit(AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK, null);
          error = AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK;
        }
      }
    }

    return error;
  }

  private _loadContractInstance(relatedContract: any, contractName: Contract) {
    if (this._web3 && this.selectedAccount) {
      switch (contractName) {
        case Contract.TOKEN:
          this._contractsInstance[contractName] = new Token(relatedContract, this._web3, this.selectedAccount);
          break;
        case Contract.UNIVERSAL_FACTORY:
          this._contractsInstance[contractName] = new UniversalFactory(relatedContract, this._web3, this.selectedAccount);
          break;
        case Contract.UNIVERSAL_GENERAL:
          this._contractsInstance[contractName] = new UniversalNFTGeneral(relatedContract, this._web3, this.selectedAccount);
          break;
        case Contract.UNIVERSAL_STAKING_FACTORY:
          this._contractsInstance[contractName] = new UniversalStaking(relatedContract, this._web3, this.selectedAccount);
          break;
        case Contract.UNIVERSAL_MINTER:
          this._contractsInstance[contractName] = new UniversalMinter(relatedContract, this._web3, this.selectedAccount);
          break;
        case Contract.UNIVERSAL_QUERIES:
          this._contractsInstance[contractName] = new UniversalQueries(relatedContract, this._web3, this.selectedAccount);
          break;
        case Contract.BATTLE_MINI_GAMES:
          this._contractsInstance[contractName] = new BattlesMiniGames(relatedContract, this._web3, this.selectedAccount);
          break;
        case Contract.UNIVERSAL_BIDDING:
          this._contractsInstance[contractName] = new Bidding(relatedContract, this._web3, this.selectedAccount);
          break;
        case Contract.UNIVERSAL_MINI_BIDDING:
          this._contractsInstance[contractName] = new MiniBidding(relatedContract, this._web3, this.selectedAccount);
          break;
        case Contract.DISTRIBUTOR:
          this._contractsInstance[contractName] = new UniversalDistributor(relatedContract, this._web3, this.selectedAccount);
          break;
        case Contract.LP_STAKING:
          this._contractsInstance[contractName] = new LPStaking(relatedContract, this._web3, this.selectedAccount);
          break;
        default:
          break;
      }
    }
  }

  get token() {
    return this._contractsInstance[Contract.TOKEN];
  }

  get universalFactory() {
    return this._contractsInstance[Contract.UNIVERSAL_FACTORY];
  }

  get universalGeneralNFT() {
    return this._contractsInstance[Contract.UNIVERSAL_GENERAL];
  }

  get universalStaking() {
    return this._contractsInstance[Contract.UNIVERSAL_STAKING_FACTORY];
  }

  get universalMinter() {
    return this._contractsInstance[Contract.UNIVERSAL_MINTER];
  }

  get universalQueries() {
    return this._contractsInstance[Contract.UNIVERSAL_QUERIES];
  }

  get battleMiniGames() {
    return this._contractsInstance[Contract.BATTLE_MINI_GAMES];
  }

  get bidding() {
    return this._contractsInstance[Contract.UNIVERSAL_BIDDING];
  }

  get miniBidding() {
    return this._contractsInstance[Contract.UNIVERSAL_MINI_BIDDING];
  }

  get distributor() {
    return this._contractsInstance[Contract.DISTRIBUTOR];
  }

  get lpStaking() {
    return this._contractsInstance[Contract.LP_STAKING];
  }

  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                             ANCHOR Validations                             */
  /* -------------------------------------------------------------------------- */

  private async _networkIsValidInContract(contract: ContractData) {
    let network: string | undefined | boolean = false;

    if (this._web3) {
      let networkId = await this._web3.eth.net.getId();

      UtilsHelpers.debugger("Load contract from " + networkId);

      if (networkId === parseInt(BlockChainHelpers.getAppChain().chainId, 16)) {
        network = contract?.data?.networks[networkId];
      }
    }

    return !!network ? network : false;
  }

  private _contractDataIsValid(contract: ContractData | null | undefined) {
    return contract !== undefined && contract !== null && !!contract.data && !!contract.contract && !!contract.data.abi;
  }

  private _contractsStateIsValid(contractsState?: ContractsState) {
    let state: ContractsState | null = contractsState ? contractsState : this._contractsData;

    return state /* && state?.Connections */;
  }

  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                             ANCHOR Getters                                 */
  /* -------------------------------------------------------------------------- */

  get selectedAccount() {
    return this._accounts?.length ? this._accounts[0] : null;
  }

  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                            ANCHOR Class actions                            */
  /* -------------------------------------------------------------------------- */

  private _destroy() {
    this.principalListener.removeAllListeners();
  }

  /* -------------------------------------------------------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                               ANCHOR Storage                               */
  /* -------------------------------------------------------------------------- */

  static saveBlockChainController(state: BlockChainState, controller: BlockChain): BlockChainState {
    return {
      ...state,
      controller,
      customer: null,
      error: null,
      firstLoad: true,
    };
  }

  static destroyBlockChainController(state: BlockChainState): BlockChainState {
    if (state.controller) state.controller._destroy();
    return { ...state, controller: null };
  }

  static setBlockChainError(state: BlockChainState, error: AppErrorCode): BlockChainState {
    if (ULTRA_ERRORS.includes(error)) {
      return { ...state, error, customer: null, controller: null };
    } else return { ...state, error };
  }

  /* -------------------------------------------------------------------------- */
}
