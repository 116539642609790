import React from "react";
import Web3 from "web3";
import Dropzone from "react-dropzone";
import IERC20 from "../../assets/contracts/static/ERC20.json";
import ReactQuill from "react-quill";

import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { AppErrorCode } from "../../core/app";
import { ApiHelpers } from "../../core/helpers/api";
import { BlockChainHelpers } from "../../core/helpers/chain";
import { Token } from "../../core/contracts/token";

import UniversalNFTAbi from "../../assets/contracts/UniversalNFT.json";
import UniversalNFTProtectedAbi from "../../assets/contracts/UniversalNFTProtected.json";

import { UtilsHelpers } from "../../core/helpers/utils";
import { toast } from "react-toastify";
import { UniversalNFT } from "../../core/contracts/universalNFT";

import { UniversalNFTV3 } from "../../core/contracts/universalNFTV3";

interface CollectionProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface CollectionState {
  collectionInstance: UniversalNFTV3 | null;
  tokenInstance: Token | null;
  collection: string;
  collectionName: string;
  collectionSymbol: string;
  isPublic: boolean;
  launched: boolean;
  paused: boolean;
  finished: boolean;
  protection: boolean;
  timeToBePublic: number;
  newPrice: number;
  newSupply: number;
  version: number;
  unlocked: number;
  contractBalance: number;
  totalUnlocked: number;
  unlockRewards: number;
  price: string;
  image: string;
  newUri: string;
  owner: string;
  baseUri: string;
  premintedNFT: MintedNFT | null;
  imageError: string | null;
  links: { [social: string]: string };
  totalMinted: Number;
  preMintedId: number;
  maxSupply: Number;
  newStakingPool: string;
  tokenSymbol: string;
  newStakingPercentage: number;
  galleryImages: string[];
  whitelist: string[];
  whitelisted: string;
  collectionInformation: {
    owner: string;
    project: string;
  };
  loginHash: string;
  withdrawData: {
    customStaking: number;
    owner: number;
    staking: number;
    total: number;
    recolector: number;
  };
}

enum Social {
  INSTAGRAM = "instagram",
  TWITTER = "twitter",
  LINKEDIN = "linkedin",
  TELEGRAM = "telegram",
  DISCORD = "discord",
}

const linksBase = {
  instagram: "",
  twitter: "",
  linkedin: "",
  telegram: "",
  discord: "",
};

interface MintedNFT {
  attributes: any[];
  background_color: string;
  description: string;
  external_url: string;
  image: string;
  name: string;
}

export class CollectionAdminPage extends React.PureComponent<CollectionProps, CollectionState> {
  constructor(props: CollectionProps) {
    super(props);

    this.state = {
      imageError: null,
      collectionInstance: null,
      tokenInstance: null,
      collection: "",
      isPublic: false,
      launched: false,
      finished: false,
      protection: false,
      paused: false,
      collectionName: "",
      collectionSymbol: "",
      totalMinted: 0,
      contractBalance: 0,
      version: 0,
      premintedNFT: null,
      maxSupply: 0,
      newPrice: 0,
      preMintedId: 0,
      newSupply: 0,
      newStakingPercentage: 0,
      newStakingPool: "",
      tokenSymbol: "",
      timeToBePublic: 0,
      unlockRewards: 0,
      unlocked: 0,
      totalUnlocked: 0,
      links: linksBase,
      price: "",
      image: "",
      baseUri: "",
      newUri: "",
      owner: "",
      whitelisted: "",
      galleryImages: [],
      whitelist: [],
      collectionInformation: {
        owner: "",
        project: "",
      },
      loginHash: "",
      withdrawData: {
        customStaking: 0,
        owner: 0,
        staking: 0,
        recolector: 0,
        total: 0,
      },
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    this.props.onToggleLoader(false);
  }

  private async _onUpdateData(error: AppErrorCode | null) {
    await this.preloadControllers();
  }

  async preloadControllers(closeLoader: boolean = false) {
    const provider = await BlockChainHelpers.getProvider();

    const contractAddress = new URLSearchParams(window.location.search).get("address");

    let collectionInstance = null;
    let tokenInstance = null;
    let collectionName = "";
    let collectionSymbol = "";
    let maxSupply = 0;
    let price = "";
    let totalMinted = 0;
    let contractBalance = 0;
    let version = 0;
    let image = "";
    let owner = "";
    let baseUri = "";
    let links = linksBase;
    let galleryImages = [];
    let isPublic = false;
    let finished = false;
    let paused = false;
    let launched = false;
    let timeToBePublic = 0;
    let unlocked = 0;
    let totalUnlocked = 0;
    let newStakingPercentage = 0;
    let newStakingPool = "";
    let protection = false;
    let tokenSymbol = "";
    let whitelist: string[] = [];

    let collectionInformation = {
      owner: "",
      project: "",
    };

    let withdrawData = {
      customStaking: 0,
      owner: 0,
      staking: 0,
      recolector: 0,
      total: 0,
    };

    if (provider && contractAddress && this.props.blockChain.controller?._web3 && this.props.blockChain.controller?.selectedAccount) {
      const collectionData = await ApiHelpers.collection(contractAddress);

      collectionInstance = new UniversalNFTV3(
        new provider.eth.Contract(UniversalNFTAbi.abi as any, contractAddress),
        this.props.blockChain.controller?._web3,
        this.props.blockChain.controller?.selectedAccount
      );

      const data = await collectionInstance.getData();

      baseUri = data.generation.uri;
      version = 2;

      if (collectionData && data) {
        const launchTime = Number(data.launch.launchTime);
        const privateTime = Number(data.launch.privateTime);
        const publicDate = new Date((launchTime + privateTime) * 1000);

        timeToBePublic = (publicDate.getTime() - Date.now()) / 1000;

        image = collectionData._image;
        owner = collectionData._owner;

        if (collectionData._projectLinks) {
          if (Object.keys(collectionData._projectLinks).length > 0) {
            const tags = Object.keys(collectionData._projectLinks);
            for (let i = 0; i < tags.length; i++) {
              const searchedIndex = Object.keys(links).findIndex((linkKey) => linkKey === tags[i]);
              if (searchedIndex !== -1) links[tags[i] as Social] = collectionData._projectLinks[tags[i]];
            }
          }
        }

        collectionInformation.owner = collectionData._aboutOwner || "";
        collectionInformation.project = collectionData._aboutProject || "";
        galleryImages = collectionData._gallery;
        maxSupply = data.generation.maxSupply;
        launched = data.launch.wasLaunched;
        collectionName = data.generation.name;
        collectionSymbol = data.generation.symbol;
        price = data.generation.price;
        totalMinted = data.minting.totalSupply;
        isPublic = data.minting.isPublic;
        newStakingPercentage = data.generation.customStakingPercentage;
        newStakingPool = data.generation.customStaking;
        whitelist = data.whitelist;

        tokenInstance = new Token(
          new provider.eth.Contract(IERC20 as any, data.generation.token),
          this.props.blockChain.controller?._web3,
          this.props.blockChain.controller?.selectedAccount
        );

        tokenSymbol = await tokenInstance.symbol();

        if (protection) {
          // unlocked = mintingData.u;
          // totalUnlocked = mintingData.tu;
          // contractBalance = mintingData.lo;
          // withdrawData.customStaking = Number(Web3.utils.fromWei(withdraw.cs, "ether"));
          // withdrawData.owner = Number(Web3.utils.fromWei(withdraw.o, "ether"));
          // withdrawData.total = Number(Web3.utils.fromWei(withdraw.t, "ether"));
          // withdrawData.staking = Number(Web3.utils.fromWei(withdraw.s, "ether"));
          // withdrawData.recolector = Number(Web3.utils.fromWei(withdraw.r, "ether"));
        } else {
          withdrawData.total = Number(Web3.utils.fromWei(data.amounts.total, "ether"));
          withdrawData.recolector = Number(Web3.utils.fromWei(data.amounts.recolector, "ether"));
          withdrawData.staking = Number(Web3.utils.fromWei(data.amounts.customStaking, "ether"));
          withdrawData.owner = Number(Web3.utils.fromWei(data.amounts.owner, "ether"));
        }
      }
    }

    this.setState(
      {
        collectionInstance,
        collectionName,
        collectionSymbol,
        owner,
        unlocked,
        totalUnlocked,
        tokenSymbol,
        protection,
        newStakingPercentage,
        newStakingPool,
        contractBalance,
        maxSupply,
        withdrawData,
        version,
        finished,
        whitelist,
        paused,
        baseUri,
        newUri: baseUri,
        galleryImages,
        timeToBePublic,
        newSupply: maxSupply,
        newPrice: Number(Web3.utils.fromWei(price)),
        price,
        launched,
        totalMinted,
        isPublic,
        image,
        collectionInformation,
        links,
        collection: contractAddress || "",
      },
      async () => {
        if (this.state.loginHash === "") await this.preloadLoginHash();
        if (closeLoader) this.props.onToggleLoader(false);
      }
    );
  }

  async preloadLoginHash() {
    if (this.props.blockChain.controller?.selectedAccount) {
      const msg = {
        domain: {
          chainId: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
          name: "BusinessBuilders.city",
          verifyingContract: this.props.blockChain.controller.universalFactory?.address,
          version: "1",
        },
        message: {
          owner: this.props.blockChain.controller.selectedAccount,
          collection: this.state.collection,
        },
        primaryType: "Request",
        types: {
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
            {
              name: "verifyingContract",
              type: "address",
            },
          ],
          Request: [
            {
              name: "owner",
              type: "address",
            },
            {
              name: "collection",
              type: "address",
            },
          ],
        },
      };

      const eth = BlockChainHelpers.getEthWindow();

      if (eth) {
        const sign = await eth.request({
          method: "eth_signTypedData_v4",
          params: [this.props.blockChain.controller.selectedAccount, JSON.stringify(msg)],
        });

        this.setState({ loginHash: sign });
      }
    }
  }

  async onDropImage(files: File[]) {
    if (UtilsHelpers.validateFiles(files, ["image/png", "image/jpeg", "image/jpg"])) {
      if (UtilsHelpers.validateFilesSize(files, 10485760)) {
        const formData = new FormData();

        formData.append("images", files[0]);

        const images = await ApiHelpers.uploadImages(formData);

        if (images && images.data && Array.isArray(images.data)) {
          if (this.state.loginHash) {
            const response = await ApiHelpers.updateCollectionImage(
              images.data[0].path,
              this.state.collection,
              this.state.loginHash,
              parseInt(BlockChainHelpers.getAppChain()?.chainId, 16)
            );

            if (response) {
              toast.success("Update image.");
              this._onUpdateData(null);
            } else {
              toast.error("Invalid image uploading, please try later or send an email to admin@businessbuilders.city.");
            }
          } else {
            toast.info("Before doing anything in your collection you could login in the web page.");
          }
        }
      } else {
        toast.error("Invalid file size.");
        this.setState({ imageError: "Invalid file size." });
      }
    } else {
      toast.error("Invalid file type.");
      this.setState({ imageError: "Invalid file extensions." });
    }
  }

  async onSaveCollection() {
    try {
      const response = await ApiHelpers.updateCollection(
        this.state.image,
        this.state.collection,
        this.state.collectionInformation.owner,
        this.state.collectionInformation.project,
        this.state.links,
        this.state.galleryImages,
        this.state.loginHash
      );

      if (response) {
        if (response?.status === false) {
          toast.error("Invalid owner");
        } else {
          toast.success("Updated collection");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  async onDropImages(files: File[]) {
    if (files.length <= 10) {
      if (UtilsHelpers.validateFiles(files, ["image/png", "image/jpeg", "image/jpg"])) {
        if (UtilsHelpers.validateFilesSize(files, 10485760)) {
          const formData = new FormData();

          for (let i = 0; i < files.length; i++) {
            formData.append("images", files[i]);
          }

          const images = await ApiHelpers.uploadImages(formData);

          if (images && images.data && Array.isArray(images.data)) {
            let galleryImages = [];

            for (let i = 0; i < images.data.length; i++) {
              galleryImages.push(images.data[i].path);
            }

            this.setState({ galleryImages });
          }
        } else {
          toast.error("Invalid file size.");
          this.setState({ imageError: "Invalid file size." });
        }
      } else {
        toast.error("Invalid file type.");
        this.setState({ imageError: "Invalid file extensions." });
      }
    } else toast.error("Invalid number of files");
  }

  async componentDidUpdate(prevProps: CollectionProps) {
    if (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount) {
      await this.preloadControllers();
    }
  }

  render() {
    const minutes = Number(this.state.timeToBePublic) / 60;
    const hours = Number(this.state.timeToBePublic) / 60 / 60;

    return (
      <React.Fragment>
        <div className="ct-collection-container ct-admin">
          <div className="ct-image">{this.state.image ? <img src={this.state.image} alt="" /> : ""}</div>
          <div className="ct-collection-presentation">
            <div className="ct-info">
              <h4>
                {this.state.collectionName} ({this.state.collectionSymbol})
              </h4>
              <span>
                <strong>minting price: </strong> {Number(Web3.utils.fromWei(this.state.price, "ether")).toFixed(2)} {this.state.tokenSymbol}
              </span>
              <span>
                <strong>Launched: </strong> {this.state.launched.toString()}
              </span>
              <span>
                <strong>Is Public: </strong> {this.state.isPublic.toString()}
              </span>
              {this.state.launched && !this.state.isPublic ? (
                <span>
                  <strong>Public in: </strong> {hours > 1 ? hours.toFixed(0) + " Hours" : minutes.toFixed(0) + " Minutes"}
                </span>
              ) : (
                ""
              )}
              <span>
                <strong>Max supply: </strong> {this.state.maxSupply}{" "}
              </span>
              <span>
                <strong>Minted: </strong> {this.state.totalMinted}{" "}
              </span>
              <span>
                <strong>Owner: </strong>{" "}
                <a href={"http://scan.meter.io/address/" + this.state.owner} target="_blank" rel="noopener noreferrer" className="ct-address">
                  {this.state.owner}
                </a>
              </span>
              {this.state.protection ? (
                <span>
                  <strong>Unlocked rewards: </strong> {this.state.unlocked} %
                </span>
              ) : (
                ""
              )}

              {this.state.protection ? (
                <span>
                  <strong>Collected founds: </strong> {Web3.utils.fromWei(this.state.contractBalance.toString() || "0", "ether")}{" "}
                  {this.state.tokenSymbol}
                </span>
              ) : (
                ""
              )}

              {this.state.protection ? (
                <span>
                  <strong>Total Unlocked rewards: </strong> {this.state.totalUnlocked} %
                </span>
              ) : (
                ""
              )}

              {this.state.version === 2 && this.state.protection ? (
                <span>
                  <strong>Owner rewards: </strong>
                  {this.state.withdrawData.owner} {this.state.tokenSymbol}
                </span>
              ) : (
                ""
              )}
              {this.state.version === 2 && this.state.protection ? (
                <span>
                  <strong>General staking rewards: </strong>
                  {this.state.withdrawData.staking} {this.state.tokenSymbol}
                </span>
              ) : (
                ""
              )}
              {this.state.version === 2 && this.state.protection ? (
                <span>
                  <strong>Recolector rewards: </strong>
                  {this.state.withdrawData.recolector} {this.state.tokenSymbol}
                </span>
              ) : (
                ""
              )}
              {this.state.version === 2 && this.state.protection ? (
                <span>
                  <strong>Custom Staking rewards: </strong>
                  {this.state.withdrawData.customStaking} {this.state.tokenSymbol}
                </span>
              ) : (
                ""
              )}
              {this.state.version === 2 ? (
                <React.Fragment>
                  <span>
                    <strong>Fees: </strong>
                    {this.state.withdrawData.recolector} {this.state.tokenSymbol}
                  </span>
                  <span>
                    <strong>Owner rewards: </strong>
                    {this.state.withdrawData.owner} {this.state.tokenSymbol}
                  </span>
                  <span>
                    <strong>Collected rewards: </strong>
                    {this.state.withdrawData.total} {this.state.tokenSymbol}
                  </span>
                </React.Fragment>
              ) : (
                ""
              )}
              {!this.state.launched ? (
                <button
                  className="ct-mt-5"
                  onClick={() => {
                    if (this.state.collectionInstance) {
                      this.state.collectionInstance.launch((error) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  Launch
                </button>
              ) : (
                ""
              )}
              {this.state.version === 2 ? (
                <button
                  disabled={!this.state.finished && this.state.protection}
                  className="ct-mt-5"
                  onClick={() => {
                    if (this.state.collectionInstance) {
                      this.state.collectionInstance.withdraw((error) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  Withdraw rewards
                </button>
              ) : (
                ""
              )}

              <small>If you close your collection the max supply will be limited to the total supply.</small>
            </div>
            <div className="ct-update">
              <h4>update contract</h4>
              <span>
                Price ({Web3.utils.fromWei(this.state.price)} {this.state.tokenSymbol})
              </span>
              <div className="ct-update-property">
                <input value={this.state.newPrice} onChange={(e) => this.setState({ newPrice: Number(e.target.value) })} type="number" />
                <button
                  onClick={() => {
                    if (this.state.collectionInstance) {
                      this.state.collectionInstance.changePrice(this.state.newPrice, (error) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  <span className="fas fa-save"></span>
                </button>
              </div>
              <span>Total supply ({this.state.maxSupply})</span>

              <div className="ct-update-property">
                <input
                  value={this.state.newSupply}
                  disabled={this.state.launched}
                  onChange={(e) => this.setState({ newSupply: Number(e.target.value) })}
                  type="number"
                />
                <button
                  disabled={this.state.launched}
                  onClick={() => {
                    if (this.state.collectionInstance) {
                      this.state.collectionInstance.changeMaxSupply(this.state.newSupply, (error) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  <span className="fas fa-save"></span>
                </button>
              </div>

              <span>Base URI </span>
              <div className="ct-update-property">
                <input value={this.state.newUri} onChange={(e) => this.setState({ newUri: e.target.value })} type="text" />
                <button
                  onClick={() => {
                    if (this.state.collectionInstance) {
                      this.state.collectionInstance.changeURI(this.state.newUri, (error) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  <span className="fas fa-save"></span>
                </button>
              </div>
            </div>
            <div className="ct-minting-test ct-mt-20">
              <h4>Minting</h4>
              {this.state.premintedNFT ? (
                <div className="ct-minted-nft">
                  <img src={this.state.premintedNFT.image} alt="" />
                  <div className="ct-info">
                    <h4>Name: {this.state.premintedNFT.name}</h4>
                  </div>
                </div>
              ) : (
                ""
              )}

              <button
                onClick={async () => {
                  if (this.props.blockChain.controller?.selectedAccount) {
                    const premintedNFT = await ApiHelpers.get(this.state.baseUri + Math.floor(Math.random() * Number(this.state.maxSupply)));

                    if (premintedNFT) this.setState({ premintedNFT });
                  }
                }}
                className="ct-main-button"
              >
                Test random mint
              </button>
              <small>Only for testing purposes.</small>
              <button
                onClick={() => {
                  if (this.state.collectionInstance) {
                    this.state.collectionInstance.mint((error) => {
                      this._onUpdateData(error);
                    });
                  }
                }}
                className="ct-main-button"
              >
                Administrator minting
              </button>
              <small>Mint the next NFT without fees. Only the admin have this option.</small>
            </div>

            {/* {this.state.version === 2 ? (
              <div className="ct-minting-test ct-staking-config ct-mt-20">
                <h4>Staking configuration</h4>
                <div className="ct-staking-pool-data">
                  <input
                    disabled={this.state.launched}
                    type="text"
                    value={this.state.newStakingPool}
                    onChange={(e) =>
                      this.setState({
                        newStakingPool: e.target.value,
                      })
                    }
                    placeholder="Staking pool contract"
                  />
                  <small>All staking founds will be sent to the pool</small>
                </div>
                <div>
                  <input
                    disabled={this.state.launched}
                    onChange={(e) =>
                      this.setState({
                        newStakingPercentage: Number(e.target.value),
                      })
                    }
                    type="number"
                    value={this.state.newStakingPercentage}
                    placeholder="Staking percentage"
                  />
                  <small>NFT value + Staking percentage will be the total price of your NFTs</small>
                </div>
                <button
                  disabled={this.state.launched}
                  onClick={() => {
                    if (this.state.collectionInstance) {
                      (this.state.collectionInstance as UniversalNFT).changeStaking(
                        this.state.newStakingPool,
                        this.state.newStakingPercentage,
                        (error) => {
                          this._onUpdateData(error);
                        }
                      );
                    }
                  }}
                  className="ct-main-button"
                >
                  Add Staking pool
                </button>
                <small>
                  Your Collection will be related to the staking pool, only the users who has your NFTs will be able to use the pool. Or you can send
                  the funds to other staking pool. First you should use our pool creator, then you will get your pool contract address. Validate the
                  inforamtion before launch your collection because you can't change it if you start the minting process.
                </small>
              </div>
            ) : (
              ""
            )} */}
          </div>

          <div className="ct-update-collection">
            <h4 className="ct-mt-10">collection image</h4>
            <div className="ct-info-update">
              <div className="ct-dropzone">
                <Dropzone onDrop={(file) => this.onDropImage(file)}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="ct-image-dropper">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>
                          Drop or click <br />
                          Select your image
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
                {this.state.imageError ? <strong>Error: {this.state.imageError}</strong> : ""}
                <small>
                  <strong>Max size: </strong> 10 MB
                </small>
                <small>
                  <strong>Max files: </strong> 1
                </small>
                <small>
                  <strong>Valid files: </strong> png, jpeg, jpg
                </small>
              </div>
            </div>
            <h4 className="ct-mt-10">collection gallery</h4>
            <div className="ct-info-update">
              <div className="ct-dropzone">
                <Dropzone onDrop={(file) => this.onDropImages(file)}>
                  {({ getRootProps, getInputProps }) => (
                    <div className="ct-image-dropper">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>
                          Drop or click <br />
                          Select your images
                        </p>
                      </div>
                    </div>
                  )}
                </Dropzone>
                {this.state.imageError ? <strong>Error: {this.state.imageError}</strong> : ""}
                <small>
                  <strong>Max size: </strong> 10 MB
                </small>
                <small>
                  <strong>Max files: </strong> 10
                </small>
                <small>
                  <strong>Valid files: </strong> png, jpeg, jpg
                </small>
              </div>
            </div>
            {this.state.galleryImages.length > 0 ? (
              <div className="ct-gallery">
                {this.state.galleryImages.map((image, index) => {
                  return <img key={index} src={image} alt="" />;
                })}
              </div>
            ) : (
              ""
            )}
            <h4 className="ct-mt-10">about the owner</h4>
            <div className="ct-info-update">
              <textarea
                name="ct-about-owner"
                id=""
                cols={30}
                rows={10}
                value={this.state.collectionInformation.owner}
                onChange={(e) =>
                  this.setState({
                    collectionInformation: {
                      ...this.state.collectionInformation,
                      owner: e.target.value,
                    },
                  })
                }
              ></textarea>
              <small>Write about you. The information will be available in your minting page.</small>
            </div>
            <h4 className="ct-mt-10">about the project</h4>
            <div className="ct-info-update">
              <ReactQuill
                className="ct-mt-10"
                value={this.state.collectionInformation.project}
                onChange={(e) => {
                  console.log(e);
                  this.setState({
                    collectionInformation: {
                      ...this.state.collectionInformation,
                      project: e,
                    },
                  });
                }}
              />
              <small className="ct-mt-10">Write about your project.</small>
            </div>
            <h4 className="ct-mt-10">Project links</h4>
            <div className="ct-info-update ct-project-links">
              {Object.keys(this.state.links).map((link, index) => {
                return (
                  <div className="ct-project-link" key={index}>
                    <div>
                      <span className={"fab fa-" + link}></span>
                    </div>
                    <input
                      value={this.state.links[link]}
                      onChange={(e) => {
                        console.log(e);
                        this.setState({
                          links: {
                            ...this.state.links,
                            [link]: e.target.value,
                          },
                        });
                      }}
                      type="text"
                      placeholder="https://social.com/businessbuildersnft"
                    />
                  </div>
                );
              })}
            </div>
            <h4>Whitelist</h4>
            <div className="ct-whitelist">
              {this.state.whitelist.map((address, index) => {
                return (
                  <div className="ct-whitelisted" key={index + "-" + address}>
                    <p>{index + 1 + ".   " + address}</p>
                    <button
                      className="ct-main-button"
                      onClick={() => {
                        if (this.state.collectionInstance) {
                          this.state.collectionInstance?.removeFromWhitelist(address, (error) => {
                            this._onUpdateData(error);
                          });
                        }
                      }}
                    >
                      <span className="fas fa-times"></span>
                    </button>
                  </div>
                );
              })}
              <div className="ct-whitelist-customer">
                <label htmlFor="whitelisted">Add new user</label>
                <input
                  name="whitelisted"
                  type="text"
                  placeholder={"0x0000000000000000000..."}
                  value={this.state.whitelisted}
                  onChange={(e) => this.setState({ whitelisted: e.target.value })}
                />
                <button
                  className="ct-main-button"
                  onClick={() => {
                    if (this.state.collectionInstance) {
                      this.state.collectionInstance.addToWhitelist(this.state.whitelisted, (error) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  <span className="fas fa-check"></span>
                </button>
              </div>
            </div>
          </div>

          <div className="ct-save" onClick={() => this.onSaveCollection()}>
            <button className="ct-main-button">Save collection information</button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
