import Web3 from "web3";
import React from "react";
import Slider from "@mui/material/Slider";

import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { AppErrorCode, Contract } from "../../core/app";
import { UniveraleStakingController } from "../../core/modules/universalStaking";

import { StakingPoolV2Component } from "../organisms/staking/poolV2";
import { OldStakingPoolComponent } from "../organisms/staking/poolV1";

import Anim1 from "../../assets/images/animations/1.png";
import Anim2 from "../../assets/images/animations/2.jpg";
import Anim3 from "../../assets/images/animations/3.gif";
import Anim4 from "../../assets/images/animations/4.gif";
import Anim5 from "../../assets/images/animations/5.jpg";
import Anim6 from "../../assets/images/animations/6.jpg";
import Anim7 from "../../assets/images/animations/7.png";
import Anim8 from "../../assets/images/animations/8.gif";
import Anim9 from "../../assets/images/animations/9.png";
import Anim10 from "../../assets/images/animations/10.png";
import Anim11 from "../../assets/images/animations/11.png";
import Anim12 from "../../assets/images/animations/12.jpg";
import Anim13 from "../../assets/images/animations/13.png";
import Anim14 from "../../assets/images/animations/14.jpg";
import Anim15 from "../../assets/images/animations/15.png";
import Anim16 from "../../assets/images/animations/16.png";

import Mini1 from "../../assets/images/miniEmployees/1_2_3_2.png";
import Mini2 from "../../assets/images/miniEmployees/3_2_2_1.png";
import Mini3 from "../../assets/images/miniEmployees/4_1_4_2.png";
import Mini4 from "../../assets/images/miniEmployees/5_4_2_4.png";
import Mini5 from "../../assets/images/miniEmployees/5_5_3_5.png";

import FTBLogo from "../../assets/images/BB_LOGO.svg";
import MTRGLogo from "../../assets/images/meter-network.png";
import GIFTIcon from "../../assets/images/icons/GIFT.svg";
import ClockIcon from "../../assets/images/icons/CLOCK.svg";
import TimesIcon from "../../assets/images/icons/TIMES.svg";

const miniImages = [Mini1, Mini2, Mini3, Mini4, Mini5];

interface StakingPoolsComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface StakingPoolsComponentState {
  backgroundImages: string[];
  shuffleInterval: any;
  universalStaking: null | UniveraleStakingController;
  factoryAllowance: number;
  searchPool: string | null;
  collectionPool: string;
  lpStakingAmount: string;
  lpStakingTimeValue: number;
  lpStakingShow: boolean;
}

export class StakingPoolsPage extends React.PureComponent<
  StakingPoolsComponentProps,
  StakingPoolsComponentState
> {
  constructor(props: StakingPoolsComponentProps) {
    super(props);

    this.state = {
      backgroundImages: [],
      shuffleInterval: null,
      universalStaking: null,
      factoryAllowance: 0,
      searchPool: null,
      collectionPool: "",
      lpStakingAmount: "",
      lpStakingTimeValue: 6,
      lpStakingShow: false,
    };
  }

  componentDidUpdate(prevProps: StakingPoolsComponentProps) {
    if (
      (!prevProps.blockChain.controller?.selectedAccount &&
        this.props.blockChain.controller?.selectedAccount) ||
      (!prevProps.appState.appData && this.props.appState.appData)
    ) {
      this.preloadControllers();
    }
  }

  async componentDidMount() {
    this.props.onToggleLoader(false);

    this.preloadControllers();
    this.loadAndSetBackground();
    this.randomizeBackground();
  }

  async loadAndSetBackground() {
    const images = [
      Anim1,
      Anim2,
      Anim3,
      Anim4,
      Anim5,
      Anim6,
      Anim7,
      Anim8,
      Anim9,
      Anim10,
      Anim11,
      Anim12,
      Anim13,
      Anim14,
      Anim15,
      Anim16,
    ];

    this.setState({
      backgroundImages: [...images, ...images, ...images, ...images, ...images],
    });
  }

  randomizeBackground() {
    const shuffleInterval = setInterval(() => {
      let shuffledArray = [...this.state.backgroundImages];
      let currentIndex = shuffledArray.length;
      let randomIndex = 0;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [shuffledArray[currentIndex], shuffledArray[randomIndex]] = [
          shuffledArray[randomIndex],
          shuffledArray[currentIndex],
        ];
      }

      this.setState({ backgroundImages: shuffledArray });
    }, 5000);

    this.setState({ shuffleInterval });
  }

  async preloadControllers() {
    let factoryAllowance = 0;
    let searchPool = null;

    if (
      this.props.blockChain.controller?.token &&
      this.props.blockChain.controller.selectedAccount &&
      this.props.appState.appData?.contractsAddress[
        Contract.UNIVERSAL_STAKING_FACTORY
      ]
    ) {
      factoryAllowance = Number(
        Web3.utils.fromWei(
          await this.props.blockChain.controller.token.allowance(
            this.props.blockChain.controller.selectedAccount,
            this.props.appState.appData?.contractsAddress[
              Contract.UNIVERSAL_STAKING_FACTORY
            ]
          ),
          "ether"
        )
      );
    }

    if (window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      const poolParam = searchParams.get("pool");
      if (poolParam) searchPool = poolParam;
    }

    this.setState({ factoryAllowance, searchPool }, () =>
      this.loadAndSetUniversalStaking()
    );
  }

  async loadAndSetUniversalStaking() {
    let universalStaking = null;

    if (
      this.props.blockChain.controller?.universalStaking &&
      this.props.blockChain.controller.lpStaking
    ) {
      universalStaking = new UniveraleStakingController(
        this.props.blockChain.controller.universalStaking,
        this.props.blockChain.controller.lpStaking
      );
      await universalStaking.initialize();
    }

    this.setState({ universalStaking });
  }

  private async _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.loadAndSetUniversalStaking();
    await this.preloadControllers();
  }

  render() {
    const pools = this.state.searchPool
      ? this.state.universalStaking?.data?.pools.filter(
          (pool) => pool._address === this.state.searchPool
        )
      : this.state.universalStaking?.data?.pools;

    const stakingAmount = Number(
      Web3.utils.fromWei(this.state.lpStakingAmount || "0")
    );
    const totalLPs = Number(
      Web3.utils.fromWei(
        this.state.universalStaking?.data?.lpStakingUserData.totalLPs || "0"
      )
    );
    const lpsBalance = Number(
      Web3.utils.fromWei(
        this.state.universalStaking?.data?.lpStakingUserData.lpsBalance || "0"
      )
    );
    const lockedAmount = Number(
      Web3.utils.fromWei(
        this.state.universalStaking?.data?.lpStakingUserData.locked.amount ||
          "0"
      )
    );
    const lpsAllowance = Number(
      Web3.utils.fromWei(
        this.state.universalStaking?.data?.lpStakingUserData.lpsAllowance || "0"
      )
    );
    const unlockTime =
      Number(
        this.state.universalStaking?.data?.lpStakingUserData.locked.unlockTime
      ) /
        60 -
      new Date().getTime() / (1000 * 60);

    const unlockParsedTime =
      unlockTime > 120
        ? unlockTime > 2880
          ? (unlockTime / 60 / 24).toFixed(0) + " days"
          : (unlockTime / 60).toFixed(0) + " hours"
        : unlockTime.toFixed(0) + " minutes";

    const parsedLockingTime = this.state.lpStakingTimeValue ** 2;

    const lockingTime =
      parsedLockingTime > 60
        ? parsedLockingTime > 365
          ? (parsedLockingTime / 365).toFixed(0) + " year"
          : (parsedLockingTime / 30).toFixed(0) + " months"
        : parsedLockingTime === 1
        ? parsedLockingTime.toFixed(0) + " day"
        : parsedLockingTime.toFixed(0) + " days";

    return (
      <React.Fragment>
        <div className="ct-create-staking-pool">
          <div className="ct-minted-animations">
            <div className="ct-list">
              {this.state.backgroundImages.map((nft, index) => {
                return (
                  <div key={index} className="ct-nft">
                    <img src={nft} alt="" />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="ct-minted-shadow"></div>
        </div>

        <div className="ct-pool-creation ct-max-container">
          <div className="ct-container">
            <h4>Create your own staking pool</h4>
            <p>
              If you want your own pool send an email to
              admin@businessbuilders.city and we will help you.
            </p>
            <button
              onClick={() => {
                if (this.props.blockChain.controller?.universalStaking) {
                  this.props.blockChain.controller.universalStaking.createPool(
                    (error) => {
                      this._onUpdateData(error);
                    }
                  );
                }
              }}
              className="ct-main-button"
            >
              create
            </button>
          </div>
        </div>

        <div className="ct-lp-staking ct-max-container">
          <div className="ct-staking">
            <div className="ct-header">
              <div className="ct-icons">
                <img src={FTBLogo} alt="" />
                <img src={MTRGLogo} alt="" />
              </div>
              <div className="ct-title">
                <h4>stake lps to earn nfts ftb-mtrg</h4>
                <p>stake your liquidity to earn daily mini employees.</p>
              </div>
            </div>
            <div className="ct-stake">
              <div className="ct-user-info">
                <p>
                  <strong>staked lps: </strong>{" "}
                  {Web3.utils.fromWei(
                    this.state.universalStaking?.data?.lpStakingUserData.locked
                      .amount || "0"
                  )}{" "}
                  /{" "}
                  {Web3.utils.fromWei(
                    this.state.universalStaking?.data?.lpStakingUserData
                      .totalLPs || "0"
                  )}{" "}
                  LPs
                </p>
                <p>
                  <strong>pool power: </strong>{" "}
                  {Number(
                    this.state.universalStaking?.data?.lpStakingUserData.power
                  ) / 100000}{" "}
                  %
                </p>
                <p>
                  <strong>locked for: </strong>{" "}
                  {unlockTime > 0 ? unlockParsedTime : 0}
                </p>
              </div>
              <button
                onClick={() => {
                  this.setState({ lpStakingShow: !this.state.lpStakingShow });
                }}
                className="ct-main-button"
              >
                stake LPs
              </button>

              {lockedAmount > 0 ? (
                <button
                  disabled={unlockTime > 0}
                  onClick={() => {
                    if (this.props.blockChain.controller?.lpStaking) {
                      this.props.blockChain.controller.lpStaking.unlock(
                        (error) => {
                          this._onUpdateData(error);
                        }
                      );
                    }
                  }}
                  className={
                    "ct-main-button" + (unlockTime > 0 ? " ct-disabled" : "")
                  }
                >
                  unstake ({lockedAmount} lps)
                </button>
              ) : (
                ""
              )}
              {this.state.lpStakingShow ? (
                <div className="ct-stake-lps-container">
                  <div className="ct-stake-lps">
                    <span
                      className="fas fa-times ct-close-button"
                      onClick={() =>
                        this.setState({
                          lpStakingShow: !this.state.lpStakingShow,
                        })
                      }
                    ></span>
                    <h4>stake LPs</h4>
                    <small>
                      you will lock your LPs in the BusinessBuilders contract,
                      you only can withdraw it when the time is up. While your
                      LPs are locked in the contract you will be able to collect
                      daily NFT rewards.
                    </small>
                    <input
                      type="number"
                      value={Web3.utils.fromWei(
                        this.state.lpStakingAmount || "0"
                      )}
                      onChange={(e) =>
                        this.setState({
                          lpStakingAmount: Web3.utils.toWei(
                            e.target.value || "0"
                          ),
                        })
                      }
                    />

                    <small>select the amount of LPs</small>

                    <div className="ct-time-selector">
                      <Slider
                        value={this.state.lpStakingTimeValue}
                        min={1}
                        max={20}
                        step={1}
                        getAriaValueText={() => lockingTime}
                        valueLabelFormat={() => lockingTime}
                        onChange={(e, v) =>
                          this.setState({
                            lpStakingTimeValue: typeof v === "number" ? v : 1,
                          })
                        }
                        valueLabelDisplay="auto"
                        aria-labelledby="non-linear-slider"
                      />

                      <small>move the slider to change to staking time</small>

                      {lockedAmount > 0 ? (
                        <button
                          className="ct-main-button"
                          onClick={() => {
                            if (this.props.blockChain.controller?.lpStaking) {
                              this.props.blockChain.controller.lpStaking.increaseLockTime(
                                this.state.lpStakingTimeValue ** 2 * 86400,
                                (error) => {
                                  this._onUpdateData(error);
                                }
                              );
                            }
                          }}
                        >
                          increase lock time ({parsedLockingTime} days)
                        </button>
                      ) : (
                        ""
                      )}
                    </div>

                    {lockedAmount > 0 ? (
                      lpsAllowance >= stakingAmount ? (
                        <button
                          onClick={() => {
                            if (this.props.blockChain.controller?.lpStaking) {
                              this.props.blockChain.controller.lpStaking.increaseLockAmount(
                                this.state.lpStakingAmount,
                                (error) => {
                                  this._onUpdateData(error);
                                }
                              );
                            }
                          }}
                          className="ct-main-button"
                        >
                          stake ({stakingAmount} LPs)
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            if (
                              this.state.universalStaking?.data
                                ?.lpStakingUserData.lpsInstance &&
                              this.props.blockChain.controller?.lpStaking
                            ) {
                              this.state.universalStaking?.data?.lpStakingUserData.lpsInstance.approve(
                                this.props.blockChain.controller?.lpStaking
                                  ?.address,
                                Number(
                                  Web3.utils.fromWei(this.state.lpStakingAmount)
                                ) * 1.1,
                                (error) => this._onUpdateData(error)
                              );
                            }
                          }}
                          className="ct-main-button"
                        >
                          approve ({stakingAmount} LPs)
                        </button>
                      )
                    ) : lpsAllowance >= stakingAmount ? (
                      <button
                        onClick={() => {
                          if (this.props.blockChain.controller?.lpStaking) {
                            this.props.blockChain.controller.lpStaking.firstLock(
                              this.state.lpStakingAmount,
                              this.state.lpStakingTimeValue ** 2 * 86400,
                              (error) => {
                                this._onUpdateData(error);
                              }
                            );
                          }
                        }}
                        className="ct-main-button"
                      >
                        stake ({stakingAmount} LPs for {parsedLockingTime} days)
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (
                            this.state.universalStaking?.data?.lpStakingUserData
                              .lpsInstance &&
                            this.props.blockChain.controller?.lpStaking
                          ) {
                            this.state.universalStaking?.data?.lpStakingUserData.lpsInstance.approve(
                              this.props.blockChain.controller?.lpStaking
                                ?.address,
                              Number(
                                Web3.utils.fromWei(this.state.lpStakingAmount)
                              ) * 1.1,
                              (error) => this._onUpdateData(error)
                            );
                          }
                        }}
                        className="ct-main-button"
                      >
                        approve ({stakingAmount} LPs)
                      </button>
                    )}

                    <small>
                      <strong>balance: </strong> {lpsBalance} LPs
                    </small>
                    <small>
                      <strong>estimated pool power: </strong>{" "}
                      {((lockedAmount + stakingAmount) * 100) /
                        (totalLPs + stakingAmount)}{" "}
                      %
                    </small>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            {(this.state.universalStaking?.data?.lpStakingUserData.rewards
              .length || 0) > 0 ? (
              <div className="ct-rewards">
                {this.state.universalStaking?.data?.lpStakingUserData.rewards.map(
                  (reward, index) => {
                    const totalTime = Number(reward.package.minTime) / 60 / 60;

                    const unlockParsedTime =
                      totalTime > 12
                        ? totalTime > 1440
                          ? (totalTime / 24 / 30).toFixed(0) + " months"
                          : (totalTime / 24).toFixed(0) + " days"
                        : totalTime.toFixed(0) + " hours";

                    return (
                      <div className="ct-reward-package" key={index}>
                        {miniImages.map((mini, index) => {
                          if (index < reward.package.mini) {
                            return <img key={index} src={mini} alt="" />;
                          } else return "";
                        })}
                        <div className="ct-info">
                          <h4>Mini package</h4>
                          <p>
                            <strong>mini employees: </strong>{" "}
                            {reward.package.mini}
                          </p>
                          <p>
                            <strong>min percentage: </strong>{" "}
                            {reward.package.minPercentage / 100} %
                          </p>
                          <p>
                            <strong>min time: </strong> {unlockParsedTime}
                          </p>
                        </div>
                        <div className="ct-request">
                          <button
                            onClick={() => {
                              if (this.props.blockChain.controller?.lpStaking) {
                                this.props.blockChain.controller.lpStaking.requestRewards(
                                  reward.packageId,
                                  (error) => {
                                    this._onUpdateData(error);
                                  }
                                );
                              }
                            }}
                            className={
                              "ct-main-button" +
                              (reward.finalValidation ? " ct-active" : "")
                            }
                          >
                            <img
                              src={
                                reward.lockedValidation &&
                                reward.packageValidation &&
                                !reward.timeValidation
                                  ? ClockIcon
                                  : !reward.lockedValidation
                                  ? TimesIcon
                                  : !reward.packageValidation
                                  ? TimesIcon
                                  : GIFTIcon
                              }
                              alt=""
                            />
                          </button>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            ) : (
              ""
            )}
            <div className="ct-icons">
              <div className="ct-icon">
                <img src={TimesIcon} alt="" />
                <p>
                  increase your position to the minimun percentage or increase
                  the lock time to the minimum time
                </p>
              </div>
              <div className="ct-icon">
                <img src={ClockIcon} alt="" />
                <p>wait for the next distribution at 0 UTC</p>
              </div>
              <div className="ct-icon">
                <img src={GIFTIcon} alt="" />
                <p>
                  you can withdraw your rewards, remember that you can only
                  request one package per day
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="ct-all-staking-pools ct-max-container">
          {pools
            ? pools.length > 0
              ? pools.map((pool, index) => {
                  return (
                    <StakingPoolV2Component
                      key={index + "new"}
                      pool={pool}
                      appState={this.props.appState}
                      blockChain={this.props.blockChain}
                    />
                  );
                })
              : ""
            : ""}
          {Array.isArray(this.state.universalStaking?.data?.oldPools)
            ? this.state.universalStaking?.data?.oldPools.map((pool, index) => {
                return (
                  <OldStakingPoolComponent
                    key={index + "old"}
                    pool={pool}
                    appState={this.props.appState}
                    blockChain={this.props.blockChain}
                  />
                );
              })
            : ""}
        </div>
      </React.Fragment>
    );
  }
}
