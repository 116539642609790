import React from "react";
import { Link } from "react-router-dom";
import { navigationLinks } from "../../../core/navigation";

import "./left.styles.css";

interface LeftNavigationProps {
  open: boolean;
  onToggleLeftNavigation: () => void;
}

interface LeftNavigationState {}

export class LeftNavigation extends React.Component<LeftNavigationProps, LeftNavigationState> {
  constructor(props: LeftNavigationProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className={"ct-left-nav" + (this.props.open ? " ct-open" : "")}>
        <div className="ct-left-nav-container">
          <div className="ct-links">
            {navigationLinks.map((parent, index) => {
              return (
                <div key={index} className="ct-link-parent">
                  <span>{parent.display}</span>
                  <div className="ct-parent-links">
                    {parent.links?.map((link, index) => {
                      if (link.type === "internal") {
                        if (link.disabled || window.location.pathname === link.href) {
                          return (
                            <span key={index} className={link.disabled || window.location.pathname === link.href ? "ct-disabled" : ""}>
                              <div className="ct-title">
                                <span>{link.display} </span>
                                {link.message ? <small>{link.message}</small> : ""}
                              </div>
                              <span className="fas fa-check"></span>
                            </span>
                          );
                        } else {
                          return (
                            <Link
                              key={index}
                              onClick={() => this.props.onToggleLeftNavigation()}
                              to={link.href}
                              className={link.disabled ? "ct-disabled" : ""}
                            >
                              <div className="ct-title">
                                <span>{link.display} </span>
                                {link.message ? <small>{link.message}</small> : ""}
                              </div>
                              <span className={link.icon}></span>
                            </Link>
                          );
                        }
                      } else {
                        return (
                          <a key={index} href={link.href} target="_blank" rel="noopener noreferrer" className={link.disabled ? "ct-disabled" : ""}>
                            <span>{link.display}</span>
                            <span className={link.icon}></span>
                          </a>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
