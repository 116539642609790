export enum AppMode {
  DEV = "dev",
  PRO = "pro",
  METER = "meter",
  TEST = "test",
  TEST_METER = "test-meter",
  TEST_POLYGON = "test-polygon",
  POLYGON = "polygon",
}

export enum AppChain {
  BSC = "bsc",
  BSCT = "bsc-test",
  METER = "meter",
  METER_TEST = "meter-test",
  POLYGON_TEST = "polygon-test",
  POLYGON = "polygon",
  GANACHE = "ganache",
}

export enum Contract {
  TOKEN = "token",
  UNIVERSAL_FACTORY = "universal-factory",
  UNIVERSAL_GENERAL = "universal-general",
  UNIVERSAL_STAKING_FACTORY = "universal-staking-factory",
  UNIVERSAL_MINTER = "universal-minter",
  UNIVERSAL_QUERIES = "universal-queries",
  UNIVERSAL_BIDDING = "bidding",
  UNIVERSAL_MINI_BIDDING = "mini-bidding",
  BATTLE_MINI_GAMES = "battle-mini-games",
  DISTRIBUTOR = "distributor",
  LP_STAKING = "lp-staking",
}

export enum AppErrorCode {
  BAD_REQUEST = "bad_request",
  INCORRECT_BLOCKCHAIN_NETWORK = "incorrect-block-chain-network",
  INVALID_PROVIDER = "invalid-provider",
  INVALID_CONTRACT_LOADING = "invalid-contract-loading",
  // NFT errors
  MINT_IS_NOT_VALID = "mint-is-not-valid",
  RESET_DEPLOYMENTS_ERROR = "reset-deployments-error",
  MINT_ERROR = "mint-error",
  ADD_EMPLOYEE_TO_FACTORY = "add-employee-to-factory-error",
  BURN_ERROR = "burn-error",
  UPGRADE_ERROR = "upgrade-error",
  GROW_ERROR = "grow-error",
  GROW_ALL_ERROR = "grow-all-error",
  CANT_GROW_ERROR = "cant-grow-error",
  CANT_GROW_ALL_ERROR = "cant-grow-all-error",
  HAVENT_UPGRADES = "havent-upgrades",
  ADD_TO_WHITELIST = "add-to-whitelist",
  ON_SAVE_MINTER_POINTS = "on-save-minter-points",
  // App errors
  USER_DENIED_TRANSACTION = "user-denied-transaction",
  INVALID_APPROVED_SPEND = "invalid-approved-spend",
  INVALID_TESTNET_REQUEST = "invalid-testnet-request",
  //Connections errors
  ADD_MODEL_ERROR = "add-model-error",
  ADD_TYPE_ERROR = "add-type-error",
  REMOVE_MODEL_ERROR = "remove-model-error",
  REMOVE_TYPE_ERROR = "remove-type-error",
  SEND_EMPLOYEE_TO_WORK_ERROR = "send-employee-to-work-error",
  //Employee staking
  SEND_EMPLOYEE_TO_STAKING = "send-employee-to-staking",
  REMOVE_EMPLOYEE_FROM_STAKING = "remove-employee-from-staking",
  TAKE_CUSTOMER_REWARDS = "take-customer-rewards",
  //Factory staking
  SEND_FACTORY_TO_STAKING = "send-factory-to-staking",
  REMOVE_FACTORY_FROM_STAKING = "remove-factory-from-staking",
  SEND_CUSTOMER_STAKING_REWARDS = "send-customer-staking-rewards",
  INVALID_POINTS = "invalid-points",
  //Token
  APPROVE_SPEND = "approved-spend",
  INCREASE_ALLOWANCE = "increase-allowance",
  //Employees
  BURN_EMPLOYEE = "burn-employee",
  APPROVE_EMPLOYEE = "approve-employee",
  SAFE_TRANSFER_FROM = "safe-transfer-from",
  //Marketplace
  SELL_ERROR = "sell-error",
  BUY_ERROR = "buy-error",
  CANCEL_SELL_ERROR = "cancel-sell-error",
  //Game
  REQUEST_TOKENS = "request-tokens",
  PLAY_ERROR = "play-error",
  //Builders
  REQUEST_ERROR = "request-error",
  //Bridge
  MINTER_REQUEST_ERROR = "request-error",
  LIMBO_REQUEST_ERROR = "limbo-error",
  //Battles
  GENERATE_RANDOM_STATS = "generate-random-stats",
}

export const ULTRA_ERRORS = [
  AppErrorCode.INVALID_PROVIDER,
  AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK,
  AppErrorCode.INVALID_CONTRACT_LOADING,
];

export const appConfig = {
  mode: AppMode.TEST_METER,
  open: true,
  confirmedBlocks: 0,
  blocktimeout: 1000,
  fastTransaction: "500",
  backendController: "https://test.back.businessbuilders.city",
};

// export const appConfig = {
//   mode: AppMode.DEV,
//   open: true,
//   confirmedBlocks: 0,
//   blocktimeout: 1000,
//   fastTransaction: "500",
//   backendController:
//     "http://localhost:8806" /* localhost:8806 https://back.businessbuilders.city */,
// };

export interface AppError {
  message: string;
  code: AppErrorCode;
}

export interface Currency {
  name?: string;
  symbol?: string;
  decimals?: number;
}

export interface ChainData {
  chainId: string;
  chainName?: string;
  nativeCurrency?: Currency;
  rpcUrls?: string[];
  blockExplorerUrls?: string[] | null;
}

export type PosibleChainsType = {
  [mode in AppChain]: ChainData;
};

export type PosibleContractAddress = {
  [mode in AppMode]:
    | {
        [contract in Contract]: string | null;
      }
    | null;
};

export const artHost = {
  [AppMode.DEV]: "https://art.businessbuilders.city/static",
  [AppMode.TEST]: "https://art.businessbuilders.city/static",
  [AppMode.PRO]: "https://art.businessbuilders.city/static",
  [AppMode.METER]: "https://art.businessbuilders.city/static",
  [AppMode.TEST_METER]: "https://art.businessbuilders.city/static",
};

export const posibleContractAddress: PosibleContractAddress = {
  [AppMode.DEV]: null,
  [AppMode.TEST]: {
    [Contract.TOKEN]: "0x934d44b5Acb8aB9DceA6425342c95f8DfB50129d",
    [Contract.UNIVERSAL_FACTORY]: null,
    [Contract.UNIVERSAL_GENERAL]: null,
    [Contract.UNIVERSAL_STAKING_FACTORY]: null,
    [Contract.UNIVERSAL_MINTER]: null,
    [Contract.UNIVERSAL_QUERIES]: null,
    [Contract.UNIVERSAL_BIDDING]: null,
    [Contract.UNIVERSAL_MINI_BIDDING]: null,
    [Contract.BATTLE_MINI_GAMES]: null,
    [Contract.DISTRIBUTOR]: null,
    [Contract.LP_STAKING]: null,
  },
  [AppMode.PRO]: null,
  [AppMode.METER]: {
    [Contract.TOKEN]: "0x228ebBeE999c6a7ad74A6130E81b12f9Fe237Ba3",
    [Contract.UNIVERSAL_FACTORY]: "0x45E6D49F264a120d6FC19C1aA30c1552b64dBb58",
    [Contract.UNIVERSAL_GENERAL]: "0x604e65Fb1D1201f6726A55C2C97a528af0c859aC",
    [Contract.UNIVERSAL_STAKING_FACTORY]:
      "0x30d1DF24E3f172650EABa46f4fD9d4527426a7f9",
    [Contract.UNIVERSAL_MINTER]: "0x4B172Ab7983f176DDd588Abf2AE74d6569633eDB",
    [Contract.UNIVERSAL_QUERIES]: "0xC38fcB823fEB1F63BD8A5691782521cFAcbCdeaE",
    [Contract.UNIVERSAL_BIDDING]: "0x69DE29cE92185f12ed7E6def5281c5C5b0041928",
    [Contract.BATTLE_MINI_GAMES]: "0xD0413aD0d3E5d1A1Fc393376a0cef4A32c88327D",
    [Contract.UNIVERSAL_MINI_BIDDING]:
      "0x6300E37F64Fa30d57c7632c389C7Aa406B3fBC44",
    [Contract.DISTRIBUTOR]: "0x9f405E89D2dAd3690eCeDda4fF00B4d29A6f9F2F",
    [Contract.LP_STAKING]: "0x5458DFfcc27Aae5Cc3eFd8dBfAf7405AA348545d",
  },
  [AppMode.TEST_METER]: {
    [Contract.TOKEN]: "0x8a419ef4941355476cf04933e90bf3bbf2f73814",
    [Contract.UNIVERSAL_FACTORY]: "0xEd1cB5655245Cfa0014002761F3091DAA0A47927",
    [Contract.UNIVERSAL_GENERAL]: "0x0Cbc6aD09F0c7fBE4d1978172905c2c3b117901D",
    [Contract.UNIVERSAL_STAKING_FACTORY]:
      "0x732A402496084fDAb183FaacDA7B62265a017dD3",
    [Contract.UNIVERSAL_MINTER]: "0x18C89eC1C3F66807d841C0F7f2ad716ac9c31aD3",
    [Contract.UNIVERSAL_QUERIES]: "0xEbf4F5e1F73b0EB42330dcCCEf70C4B3A031f14D",
    [Contract.UNIVERSAL_BIDDING]: "0x8bD918617d171a164d6Fa0532eC01BEf403221bF",
    [Contract.BATTLE_MINI_GAMES]: "0x32539777951DB8600E67A192a9c8D70eCc2eD0E1",
    [Contract.UNIVERSAL_MINI_BIDDING]:
      "0xf11dF374d9013166F357830B3B6e08a19b30EDBA",
    [Contract.DISTRIBUTOR]: "0x8E642D0F4edf9915D4c1dACc35AC59846c577DF2",
    [Contract.LP_STAKING]: "0x8EB56B1B58E4bf0aF009b367E53123D18eCf49eE",
  },
  [AppMode.TEST_POLYGON]: {
    [Contract.TOKEN]: "0x8D8190931173A3B28EF8B9C4106Faf9771Aa105c",
    [Contract.UNIVERSAL_FACTORY]: null,
    [Contract.UNIVERSAL_GENERAL]: null,
    [Contract.UNIVERSAL_STAKING_FACTORY]: null,
    [Contract.UNIVERSAL_MINTER]: null,
    [Contract.UNIVERSAL_QUERIES]: null,
    [Contract.UNIVERSAL_BIDDING]: null,
    [Contract.BATTLE_MINI_GAMES]: null,
    [Contract.UNIVERSAL_MINI_BIDDING]: null,
    [Contract.DISTRIBUTOR]: null,
    [Contract.LP_STAKING]: null,
  },
  [AppMode.POLYGON]: {
    [Contract.TOKEN]: "0xF305012EA754252184f1071C86ae99fAc5B40320",
    [Contract.UNIVERSAL_FACTORY]: null,
    [Contract.UNIVERSAL_GENERAL]: null,
    [Contract.UNIVERSAL_STAKING_FACTORY]: null,
    [Contract.UNIVERSAL_MINTER]: null,
    [Contract.UNIVERSAL_QUERIES]: null,
    [Contract.UNIVERSAL_BIDDING]: null,
    [Contract.BATTLE_MINI_GAMES]: null,
    [Contract.UNIVERSAL_MINI_BIDDING]: null,
    [Contract.DISTRIBUTOR]: null,
    [Contract.LP_STAKING]: null,
  },
};

export const relatedModeToChain: { [key in AppChain]: AppMode } = {
  [AppChain.BSC]: AppMode.PRO,
  [AppChain.METER]: AppMode.METER,
  [AppChain.POLYGON]: AppMode.POLYGON,
  [AppChain.BSCT]: AppMode.TEST,
  [AppChain.GANACHE]: AppMode.DEV,
  [AppChain.METER_TEST]: AppMode.TEST_METER,
  [AppChain.POLYGON_TEST]: AppMode.TEST_POLYGON,
};

export const posibleChains: PosibleChainsType = {
  [AppChain.BSC]: {
    chainId: "0x38",
    chainName: AppChain.BSC,
    nativeCurrency: {
      symbol: "BNB",
      name: "Binance",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  [AppChain.BSCT]: {
    chainId: "0x61",
    chainName: AppChain.BSCT,
    nativeCurrency: {
      symbol: "BNB",
      name: "Binance",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
  [AppChain.GANACHE]: {
    chainId: "0x7a69",
    chainName: AppChain.GANACHE,
    nativeCurrency: {
      symbol: "MATIC",
      name: "Matic",
      decimals: 18,
    },
    rpcUrls: ["http://localhost:8545"],
    blockExplorerUrls: ["http://localhost:8545"],
  },
  [AppChain.METER_TEST]: {
    chainId: "0x53",
    chainName: AppChain.METER_TEST,
    nativeCurrency: {
      symbol: "MTR",
      name: "METER",
      decimals: 18,
    },
    rpcUrls: ["https://rpctest.meter.io"],
    blockExplorerUrls: ["https://scan-warringstakes.meter.io"],
  },
  [AppChain.POLYGON_TEST]: {
    chainId: "0x13881",
    chainName: AppChain.POLYGON_TEST,
    nativeCurrency: {
      symbol: "MATIC",
      name: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
  },
  [AppChain.METER]: {
    chainId: "0x52",
    chainName: AppChain.METER,
    nativeCurrency: {
      symbol: "MTR",
      name: "METER",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.meter.io"],
    blockExplorerUrls: ["https://scan.meter.io"],
  },
  [AppChain.POLYGON]: {
    chainId: "0x89",
    chainName: AppChain.POLYGON,
    nativeCurrency: {
      symbol: "MATIC",
      name: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-rpc.com"],
    blockExplorerUrls: ["https://polygonscan.com"],
  },
};
