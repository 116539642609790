import { ContractsState } from "../storage/state/contracts/state";
import { Contract } from "./app";
import { UtilsHelpers } from "./helpers/utils";

// Test Contracts

import Token from "../assets/contracts/Token.json";
import UniversalFactory from "../assets/contracts/UniversalNFTFactory.json";
import UniversalNFTGeneral from "../assets/contracts/UniversalNFTGeneral.json";
import UniversalStakingFactory from "../assets/contracts/UniversalStakingFactory.json";
import UniversalNFTMinter from "../assets/contracts/UniversalMinter.json";
import UniversalQueries from "../assets/contracts/ERC721Queries.json";
import BattleMiniGames from "../assets/contracts/DailyMiniBattles.json";
import UniversalBidding from "../assets/contracts/UniversalNFTBidding.json";
import UniversalMiniBidding from "../assets/contracts/UniversalNFTMiniBidding.json";
import UniversalDistributor from "../assets/contracts/UniversalDistributor.json";
import LPStaking from "../assets/contracts/LPStaking.json";

export interface NormalizedABI {
  networks: ABINetworks;
  [property: string]: any;
}

export type ABINetworkData = {
  events: any;
  links: any;
  address: string;
  transactionHash: string;
};

export type ABINetworks = {
  [id: string]: string;
};

export type ContractData = {
  data: NormalizedABI | null;
  contract: Contract;
};

export class ContractsStateController {
  static contractBasePath: string = "/assets/contracts/";

  static loadedContracts: any = {
    [Contract.TOKEN]: Token,
    [Contract.UNIVERSAL_FACTORY]: UniversalFactory,
    [Contract.UNIVERSAL_GENERAL]: UniversalNFTGeneral,
    [Contract.UNIVERSAL_STAKING_FACTORY]: UniversalStakingFactory,
    [Contract.UNIVERSAL_MINTER]: UniversalNFTMinter,
    [Contract.UNIVERSAL_QUERIES]: UniversalQueries,
    [Contract.UNIVERSAL_BIDDING]: UniversalBidding,
    [Contract.UNIVERSAL_MINI_BIDDING]: UniversalMiniBidding,
    [Contract.BATTLE_MINI_GAMES]: BattleMiniGames,
    [Contract.DISTRIBUTOR]: UniversalDistributor,
    [Contract.LP_STAKING]: LPStaking,
  };

  public static async loadContractAbiMock(path: string, callback?: (error: boolean, abiData?: any) => void) {
    fetch(path)
      .then((result) => result.json())
      .then((json: any) => {
        return callback && json ? callback(false, json) : callback ? callback(true) : null;
      })
      .catch((err) => {
        UtilsHelpers.debugger(err?.code + " " + path);
        return callback ? callback(true) : null;
      });
  }

  public static loadContractAbi(contract: Contract) {
    return ContractsStateController.loadedContracts[contract];
  }

  public static isLoaded(state: ContractsState) {
    let isValid = true;
    let keys = Object.keys(state);

    for (let i = 0; i < keys.length; i++) {
      if (state[keys[i] as Contract] === null) isValid = false;
    }

    return isValid;
  }

  /* -------------------------------------------------------------------------- */
  /*                               ANCHOR Storage                               */
  /* -------------------------------------------------------------------------- */

  public static setContractAbi(state: ContractsState, contract: string, abiData: any): ContractsState {
    return {
      ...state,
      [contract]: { ...state[contract as Contract], data: abiData },
    };
  }

  public static setMultipleContractsAbi(state: ContractsState, contracts: ContractData[]): ContractsState {
    let obj: any = {};

    for (let i = 0; i < contracts.length; i++) {
      obj[contracts[i].contract] = contracts[i];
    }

    return { ...state, ...obj } as ContractsState;
  }

  /* -------------------------------------------------------------------------- */
}
