import React from "react";
import Web3 from "web3";

import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { appConfig, AppErrorCode, AppMode, Contract } from "../../core/app";

import Anim1 from "../../assets/images/animations/1.png";
import Anim2 from "../../assets/images/animations/2.jpg";
import Anim3 from "../../assets/images/animations/3.gif";
import Anim4 from "../../assets/images/animations/4.gif";
import Anim5 from "../../assets/images/animations/5.jpg";
import Anim6 from "../../assets/images/animations/6.jpg";
import Anim7 from "../../assets/images/animations/7.png";
import Anim8 from "../../assets/images/animations/8.gif";
import Anim9 from "../../assets/images/animations/9.png";
import Anim10 from "../../assets/images/animations/10.png";
import Anim11 from "../../assets/images/animations/11.png";
import Anim12 from "../../assets/images/animations/12.jpg";
import Anim13 from "../../assets/images/animations/13.png";
import Anim14 from "../../assets/images/animations/14.jpg";
import Anim15 from "../../assets/images/animations/15.png";
import Anim16 from "../../assets/images/animations/16.png";

import MTRGLogo from "../../assets/images/meter-network.png";
import FTBLogo from "../../assets/images/BB_LOGO.svg";
import VoltLogo from "../../assets/images/VOLT.png";

import { ApiHelpers } from "../../core/helpers/api";
import { DistributionComponent } from "../organisms/distribution/distribution";
import { UniverseMintingController } from "../../core/modules/universalMinting";

interface DistributionProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface DistributionState {
  backgroundImages: string[];
  shuffleInterval: any;
  distributionData: any;
}

export class DistributionPage extends React.PureComponent<DistributionProps, DistributionState> {
  constructor(props: DistributionProps) {
    super(props);

    this.state = {
      backgroundImages: [],
      shuffleInterval: null,
      distributionData: null,
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    this.loadAndSetBackground();
    this.randomizeBackground();
    this.props.onToggleLoader(false);
  }

  componentDidUpdate(prevProps: DistributionProps) {
    if (
      (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount) ||
      (!prevProps.appState.appData && this.props.appState.appData)
    ) {
      this.preloadControllers();
    }
  }

  async loadAndSetBackground() {
    const images = [Anim1, Anim2, Anim3, Anim4, Anim5, Anim6, Anim7, Anim8, Anim9, Anim10, Anim11, Anim12, Anim13, Anim14, Anim15, Anim16];

    this.setState({
      backgroundImages: [...images, ...images, ...images, ...images, ...images],
    });
  }

  randomizeBackground() {
    const shuffleInterval = setInterval(() => {
      let shuffledArray = [...this.state.backgroundImages];
      let currentIndex = shuffledArray.length;
      let randomIndex = 0;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [shuffledArray[currentIndex], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[currentIndex]];
      }

      this.setState({ backgroundImages: shuffledArray });
    }, 5000);

    this.setState({ shuffleInterval });
  }

  async preloadControllers() {
    if (this.props.appState.appData?.contractsAddress[Contract.TOKEN]) {
      const data = await this.props.blockChain.controller?.distributor?.getData();
      this.setState({ distributionData: data });
    }
  }

  async _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.preloadControllers();
  }

  componentWillUnmount() {
    if (this.state.shuffleInterval) clearInterval(this.state.shuffleInterval);
  }

  render() {
    return (
      <React.Fragment>
        <div className="ct-distribution">
          <div className="ct-minted-animations">
            <div className="ct-list">
              {this.state.backgroundImages.map((nft, index) => {
                return (
                  <div key={index} className="ct-nft">
                    <img src={nft} alt="" />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="ct-minted-shadow"></div>

          <div className="ct-distributions ct-max-container">
            {this.state.distributionData?.tokens?.map((token: any, index: number) => {
              return (
                <DistributionComponent
                  key={index}
                  data={{
                    token,
                    balance: this.state.distributionData.balances[index],
                    buyBurnPercentage: this.state.distributionData.buyBurnPercentage,
                    stakingPercentage: this.state.distributionData.stakingPercentage,
                  }}
                  blockChain={this.props.blockChain}
                  appState={this.props.appState}
                  onUpdate={(error) => this._onUpdateData(error)}
                ></DistributionComponent>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
