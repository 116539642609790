import React from "react";
import Dropzone from "react-dropzone";
import * as Scroll from "react-scroll";

import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { TokenController } from "../../core/modules/token";
import { UtilsHelpers } from "../../core/helpers/utils";
import { appConfig, AppErrorCode, Contract } from "../../core/app";
import { ApiHelpers } from "../../core/helpers/api";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";

import Anim1 from "../../assets/images/animations/1.png";
import Anim2 from "../../assets/images/animations/2.jpg";
import Anim3 from "../../assets/images/animations/3.gif";
import Anim4 from "../../assets/images/animations/4.gif";
import Anim5 from "../../assets/images/animations/5.jpg";
import Anim6 from "../../assets/images/animations/6.jpg";
import Anim7 from "../../assets/images/animations/7.png";
import Anim8 from "../../assets/images/animations/8.gif";
import Anim9 from "../../assets/images/animations/9.png";
import Anim10 from "../../assets/images/animations/10.png";
import Anim11 from "../../assets/images/animations/11.png";
import Anim12 from "../../assets/images/animations/12.jpg";
import Anim13 from "../../assets/images/animations/13.png";
import Anim14 from "../../assets/images/animations/14.jpg";
import Anim15 from "../../assets/images/animations/15.png";
import Anim16 from "../../assets/images/animations/16.png";
import PromotionImage from "../../assets/images/universities.jpg";
import { UniverseMintingController } from "../../core/modules/universalMinting";

interface SoftNFT {
  _id: number;
  _name: string;
  _collection: string;
  _metadata?: any[];
  _image: string;
  _softID: number;
  _mintedID: number;
}

interface Collection {
  open: boolean;
  name: string;
}

interface UniversalMinterComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface NFT {
  id: number;
  collection: string;
  name: string;
  image: string;
  metadata: { type: string; data: string }[];
}

interface UniversalMinterComponentState {
  tokenAllowance: number;
  imageSelector: number;
  collectionName: string;
  error: null | string;
  token: null | TokenController;
  universalMinting: null | UniverseMintingController;
  selectedImages: File[];
  softNFTs: NFT[];
  lastMintedNFTs: any[];
  shuffleInterval: any;
  mintingPage: boolean;
  //My NFTs
  customerSoftNFTs: SoftNFT[];
  softCollections: Collection[];
  customerMintedNFTs: SoftNFT[];
  mintedCollections: Collection[];
}

export class UniversalMinterPage extends React.PureComponent<UniversalMinterComponentProps, UniversalMinterComponentState> {
  constructor(props: UniversalMinterComponentProps) {
    super(props);

    this.state = {
      collectionName: "",
      tokenAllowance: 0,
      imageSelector: 0,
      token: null,
      universalMinting: null,
      error: null,
      selectedImages: [],
      softNFTs: [],
      lastMintedNFTs: [],
      shuffleInterval: null,
      mintingPage: true,
      // My NFTs
      customerSoftNFTs: [],
      softCollections: [],
      customerMintedNFTs: [],
      mintedCollections: [],
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    this.loadAndSetLastMinted();
    this.randomizeMintedNFTs();

    this.props.onToggleLoader(false);

    Scroll.scrollSpy.update();
  }

  randomizeMintedNFTs() {
    const shuffleInterval = setInterval(() => {
      let shuffledArray = [...this.state.lastMintedNFTs];
      let currentIndex = shuffledArray.length;
      let randomIndex = 0;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [shuffledArray[currentIndex], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[currentIndex]];
      }

      this.setState({ lastMintedNFTs: shuffledArray });
    }, 5000);

    this.setState({ shuffleInterval });
  }

  componentWillUnmount() {
    if (this.state.shuffleInterval) clearInterval(this.state.shuffleInterval);
  }

  async preloadControllers(closeLoader: boolean = false) {
    let token = null;
    let tokenAllowance = 0;
    let universalNFT = null;

    if (this.props.blockChain.controller?.token && this.props.appState.appData) {
      token = new TokenController(this.props.blockChain.controller.token);

      tokenAllowance = UtilsHelpers.normalizeWei(
        await this.props.blockChain.controller.token.allowance(
          this.props.blockChain.controller.token.selectedAccount,
          this.props.appState.appData?.contractsAddress[Contract.UNIVERSAL_MINTER]
        )
      );
    }

    if (this.props.blockChain.controller?.universalMinter) {
      universalNFT = new UniverseMintingController(this.props.blockChain.controller.universalMinter);
      await universalNFT.initialize();
    }

    this.setState(
      {
        token,
        tokenAllowance,
        universalMinting: universalNFT,
      },
      () => {
        if (closeLoader) this.props.onToggleLoader(false);
        this.loadAndSetSoftNFTs();
      }
    );
  }

  async onDropImage(files: File[]) {
    if (UtilsHelpers.validateFiles(files, ["image/png", "image/jpeg", "image/jpg", "image/gif"])) {
      if (UtilsHelpers.validateFilesSize(files, 1048576)) {
        const promises = [];

        for (let i = 0; i < files.length; i++) {
          const fileReader = new FileReader();

          promises.push(
            new Promise((res) => {
              fileReader.onload = () => {
                res({
                  image: fileReader.result,
                  id: i,
                  name: i + 1,
                } as unknown);
              };

              fileReader.readAsDataURL(files[i]);
            })
          );
        }

        const resolved = await Promise.all(promises);

        this.setState({
          selectedImages: files,
          imageSelector: 2,
          softNFTs: resolved as NFT[],
        });
      } else this.setState({ error: "Invalid file size." });
    } else this.setState({ error: "Invalid file extensions." });
  }

  async softMint() {
    const multipart = new FormData();
    const allNFTs = [];

    for (let i = 0; i < this.state.softNFTs.length; i++) {
      multipart.append("images", this.state.selectedImages[i], i.toString());

      allNFTs.push({
        ...this.state.softNFTs[i],
        collection: this.state.collectionName,
      });
    }

    // Upload images
    const uploadImages = await ApiHelpers.uploadImages(multipart);

    if (uploadImages && uploadImages.data && Array.isArray(uploadImages.data)) {
      for (let i = 0; i < uploadImages.data.length; i++) {
        allNFTs[i].image = uploadImages.data[i].path;
      }

      const softMinting = await ApiHelpers.saveSoftNFTs({
        soft: allNFTs,
        owner: this.props.blockChain.controller?.selectedAccount,
      });

      if (softMinting && softMinting.state) {
        toast.success("Valid soft minting. Go to 'my NFTs' section to see your soft minted NFTs.");

        this.setState(
          {
            selectedImages: [],
            softNFTs: [],
            imageSelector: 0,
            collectionName: "",
          },
          () => this.preloadControllers()
        );
      } else toast.error("Invalid soft minting. Please, Try later.");
    } else toast.error("Invalid image uploading. Please, try later.");
  }

  async loadAndSetLastMinted() {
    this.setState({
      lastMintedNFTs: [
        Anim1,
        Anim2,
        Anim3,
        Anim4,
        Anim5,
        Anim6,
        Anim7,
        Anim8,
        Anim9,
        Anim10,
        Anim11,
        Anim12,
        Anim13,
        Anim14,
        Anim15,
        Anim16,
        Anim1,
        Anim2,
        Anim3,
        Anim4,
        Anim5,
        Anim6,
        Anim7,
        Anim8,
        Anim9,
        Anim10,
        Anim11,
        Anim12,
        Anim13,
        Anim14,
        Anim15,
        Anim16,
        Anim1,
        Anim2,
        Anim3,
        Anim4,
        Anim5,
        Anim6,
        Anim7,
        Anim8,
        Anim9,
        Anim10,
        Anim11,
        Anim12,
        Anim13,
        Anim14,
        Anim15,
        Anim16,
        Anim1,
        Anim2,
        Anim3,
        Anim4,
        Anim5,
        Anim6,
        Anim7,
        Anim8,
        Anim9,
        Anim10,
        Anim11,
        Anim12,
        Anim13,
        Anim14,
        Anim15,
        Anim16,
        Anim1,
        Anim2,
        Anim3,
        Anim4,
        Anim5,
        Anim6,
        Anim7,
        Anim8,
        Anim9,
        Anim10,
        Anim11,
        Anim12,
        Anim13,
        Anim14,
        Anim15,
        Anim16,
      ],
    });
  }

  async loadAndSetSoftNFTs() {
    let customerSoftNFTs: SoftNFT[] = [];
    let softCollections: Collection[] = [];

    if (this.props.blockChain.controller?.selectedAccount) {
      await ApiHelpers.preloadMintedNFTs(this.state.universalMinting?.data?.tokens || []);
      customerSoftNFTs = await ApiHelpers.getSoftNFTs(this.props.blockChain?.controller?.selectedAccount);

      if (Array.isArray(customerSoftNFTs)) {
        for (let i = 0; i < customerSoftNFTs.length; i++) {
          let validated = false;

          for (let j = 0; j < softCollections.length; j++) {
            if (softCollections[j].name === customerSoftNFTs[i]._collection) {
              validated = true;
            }
          }

          if (validated === false) {
            softCollections.push({
              name: customerSoftNFTs[i]._collection,
              open: false,
            });
          }
        }
      }
    }

    this.setState({ customerSoftNFTs, softCollections });
  }

  private async _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    await this.preloadControllers();
  }

  componentDidUpdate(prevProps: UniversalMinterComponentProps) {
    if (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount) {
      this.preloadControllers();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="ct-universal-minter">
          <div className="ct-minted-animations">
            <div className="ct-list">
              {this.state.lastMintedNFTs.map((nft, index) => {
                return (
                  <div key={index} className="ct-nft">
                    <img src={nft} alt="" />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="ct-minted-shadow"></div>
          <div className="ct-pt-20 ct-max-container ct-universal-minter-container">
            <div className="ct-soft-mint">
              <h3>mint your own nfts</h3>
              <small>Soft mint</small>
              <div className="ct-new-nft">
                <div className="ct-normal-data">
                  <div className="ct-collection">
                    <label htmlFor="ct-collection">
                      <strong>collection name</strong>
                    </label>
                    <input
                      type="text"
                      name="ct-collection"
                      id="ct-collection"
                      placeholder="COLLECTION NAME"
                      value={this.state.collectionName}
                      onChange={(e) => {
                        this.setState({ collectionName: e.target.value });
                      }}
                    />
                  </div>
                </div>
                {this.state.imageSelector !== 2 ? (
                  <div className="ct-image-selector">
                    <button onClick={() => this.setState({ imageSelector: 0 })}>Upload image</button>
                    <button disabled onClick={() => this.setState({ imageSelector: 1 })}>
                      Use image URL
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {this.state.imageSelector === 2 ? (
                  <div className="ct-selected-images">
                    {this.state.softNFTs
                      ? this.state.softNFTs.map((nft: NFT, index: number) => {
                          return (
                            <div className="ct-nft-creator" key={"ct-image-element-" + index}>
                              <div className="ct-selected-image">
                                <img src={nft.image} id={"ct-image-element-" + index} alt="" />
                              </div>
                              <div className="ct-preview">
                                <small>{"Universal NFT"}</small>
                                <span>
                                  {this.state.collectionName} - {this.state.softNFTs[index].name}
                                </span>
                              </div>
                              <div className="ct-data">
                                <div className="ct-separator">
                                  <label htmlFor="ct-name">Name</label>
                                  <input
                                    onChange={(e) => {
                                      const newState = [...this.state.softNFTs];
                                      newState[index].name = e.target.value;
                                      this.setState({ softNFTs: newState });
                                    }}
                                    value={this.state.softNFTs[index].name}
                                    type="text"
                                  />
                                </div>
                                {this.state.softNFTs[index]?.metadata?.map((_, metaIndex) => {
                                  return (
                                    <div key={"meta-key-" + metaIndex} className="ct-separator">
                                      <label htmlFor="ct-name">Property name</label>
                                      <input
                                        type="text"
                                        onChange={(e) => {
                                          const selectedNFT = this.state.softNFTs.findIndex((soft) => soft.id === nft.id);

                                          const newState = [...this.state.softNFTs];

                                          newState[selectedNFT].metadata[metaIndex].type = e.target.value;

                                          this.setState({
                                            softNFTs: newState,
                                          });
                                        }}
                                        value={this.state.softNFTs[index].metadata[metaIndex].type}
                                        placeholder="Property name"
                                      />
                                      <label htmlFor="ct-value">Property value</label>
                                      <input
                                        onChange={(e) => {
                                          const selectedNFT = this.state.softNFTs.findIndex((soft) => soft.id === nft.id);

                                          const newState = [...this.state.softNFTs];

                                          newState[selectedNFT].metadata[metaIndex].data = e.target.value;

                                          this.setState({
                                            softNFTs: newState,
                                          });
                                        }}
                                        value={this.state.softNFTs[index].metadata[metaIndex].data}
                                        placeholder="Value"
                                        type="text"
                                      />
                                      <button
                                        onClick={() => {
                                          const newState = [...this.state.softNFTs];
                                          newState[index].metadata.splice(metaIndex, 1);
                                          this.setState({
                                            softNFTs: newState,
                                          });
                                        }}
                                      >
                                        Remove property
                                      </button>
                                    </div>
                                  );
                                })}
                              </div>
                              <div className="ct-add-meta-data">
                                <button
                                  onClick={() => {
                                    const newState = [...this.state.softNFTs];
                                    newState[index].metadata = [...(newState[index].metadata || []), { type: "", data: "" }];
                                    this.setState({ softNFTs: newState });
                                  }}
                                >
                                  Add metadata
                                </button>
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                ) : (
                  <div className="ct-image">
                    {this.state.imageSelector === 0 ? (
                      <div className="ct-dropzone">
                        <Dropzone onDrop={(file) => this.onDropImage(file)}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="ct-image-dropper">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>
                                  Drop or click <br />
                                  Select your image
                                </p>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        {this.state.error ? <strong>Error: {this.state.error}</strong> : ""}
                        <small>
                          <strong>Max size: </strong> 1 MB per file
                        </small>
                        <small>
                          <strong>Max files: </strong> 10
                        </small>
                        <small>
                          <strong>Valid files: </strong> png, jpeg, jpg
                        </small>
                      </div>
                    ) : this.state.imageSelector === 1 ? (
                      <div className="ct-url">
                        <div className="ct-urls">
                          <input type="text" placeholder="Image url" />
                        </div>
                        <button>Add URL</button>
                        <button>Generate URL secuence.</button>
                        <small>
                          Use {"{data}"} to generate many urls. Example https://domain.com/{"{id}"}
                        </small>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
              <div className="ct-actions">
                <h4>soft mint information</h4>
                <p>
                  Your NFT will be generated offchain, you can validate the image, name and metadata before minting it. Then you can mint your NFT
                  using the MeterIO{" "}
                  <a href="https://meter.io/" target="_blank" rel="noopener noreferrer">
                    MeterIO
                  </a>{" "}
                  blockchain.
                </p>
                <div className="ct-buttons">
                  <button
                    disabled={this.state.softNFTs.length === 0}
                    onClick={() =>
                      this.setState({
                        selectedImages: [],
                        softNFTs: [],
                        imageSelector: 0,
                        collectionName: "",
                      })
                    }
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => this.softMint()}
                    disabled={this.state.softNFTs.length === 0 || !this.props.blockChain.controller?.selectedAccount}
                  >
                    Soft Mint
                  </button>
                </div>
              </div>
              <div className="ct-go-to-mint">
                {this.props.blockChain.controller?.selectedAccount ? (
                  <Scroll.Link activeClass="active" to="test1" spy={true} smooth={true} offset={-100} duration={500}>
                    <button className="ct-main-button ct-go-to">Go to minting section</button>
                  </Scroll.Link>
                ) : (
                  <div className="ct-connect">
                    <h4>Connect your wallet and mint your own NFTs</h4>
                    <button className="ct-main-button" onClick={() => this.props.onLoadBlockChain()}>
                      Connect
                    </button>
                  </div>
                )}
              </div>
              {this.state.universalMinting?.data ? (
                <div className="ct-universal-key">
                  <div className="ct-key">
                    <img src={Anim3} alt="Lighting key" />
                  </div>
                  <div className="ct-data">
                    <h4>
                      Universal Key{" "}
                      <small>
                        ({this.state.universalMinting.data.keysSupply} / {this.state.universalMinting.data.maxKeys})
                      </small>
                    </h4>
                    <small>Open all doors in our NFT universe.</small>
                    <a href="https://docsuninft.businessbuilders.city/meter/universal-keys" target="_blank" rel="noopener noreferrer">
                      read more
                    </a>
                    {this.state.tokenAllowance >= this.state.universalMinting.data.keyPrice ? (
                      <button
                        onClick={() => {
                          if (this.props.blockChain.controller) {
                            this.props.blockChain.controller.universalMinter?.mintKey((error) => {
                              this._onUpdateData(error);
                            });
                          }
                        }}
                      >
                        buy ({this.state.universalMinting?.data?.keyPrice.toFixed(3)} MTRG)
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (
                            this.props.blockChain.controller &&
                            this.props.blockChain.controller.universalMinter &&
                            this.state.universalMinting?.data
                          ) {
                            this.props.blockChain.controller.token?.approve(
                              this.props.blockChain.controller.universalMinter?.address,
                              this.state.universalMinting?.data?.keyPrice + this.state.universalMinting?.data?.keyPrice * 0.1,
                              (error) => {
                                this._onUpdateData(error);
                              }
                            );
                          }
                        }}
                      >
                        approve ({this.state.universalMinting?.data?.keyPrice.toFixed(3)} MTRG)
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <Scroll.Element name="test1" />
        <div className="ct-promotor ct-max-container">
          <div className="ct-promotion-container">
            <div className="ct-image">
              <img src={PromotionImage} alt="" />
            </div>
            <div className="ct-data">
              <h4>FTB and DLCs burning system</h4>
              <p>Promote your project using our fully decentralized promotion system (100% tokens burned).</p>
              <button disabled className="ct-main-button">
                comming soon
              </button>
            </div>
          </div>
        </div>
        {this.props.blockChain?.controller?.selectedAccount ? (
          <div className="ct-my-nfts ct-max-container">
            <div className="ct-my-nfts-container">
              {this.state.customerSoftNFTs?.length > 0 ? (
                <div className="ct-soft-nfts">
                  <h4>nft collections</h4>
                  <div className="ct-collections">
                    {this.state.softCollections.map((collection, index) => {
                      return (
                        <div className="ct-collection-grid" key={index}>
                          <div className="ct-collection-header">
                            <h4>Collection: {collection.name ? collection.name : "undefined collection"}</h4>
                            <div className="ct-actions"></div>
                          </div>
                          {this.state.customerSoftNFTs
                            .filter((soft) => soft._collection === collection.name)
                            .map((soft, index) => {
                              return (
                                <div key={index + soft._softID} className="ct-nft-data">
                                  <LazyLoadImage src={soft._image} alt={soft._name} />
                                  <div className="ct-info">
                                    <button
                                      disabled={!!soft._mintedID}
                                      onClick={() => {
                                        this.props.blockChain.controller?.universalMinter?.mintGeneralNFT(soft._softID.toString(), (error) =>
                                          this._onUpdateData(error)
                                        );
                                      }}
                                    >
                                      {soft._mintedID ? "Minted" : "Mint NFT"}
                                    </button>
                                    <div className="ct-actions">
                                      <div className="ct-names">
                                        <small>
                                          <strong>Collection:</strong> {soft._collection}
                                        </small>
                                        <small>
                                          <strong>Name:</strong> {soft._name}
                                        </small>
                                        <small>
                                          <strong>ID:</strong> {soft._mintedID}
                                        </small>
                                      </div>
                                      <div className="ct-links">
                                        <a
                                          href={appConfig.backendController + "/universalNFT?soft=" + soft._softID}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          <span className="fas fa-database"></span>
                                        </a>
                                        {!!soft._mintedID ? (
                                          <a
                                            href={
                                              "https://tofunft.com/nft/meter/" +
                                              this.props.appState.appData?.contractsAddress[Contract.UNIVERSAL_GENERAL] +
                                              "/" +
                                              soft._mintedID
                                            }
                                            rel="noopener noreferrer"
                                            target="_blank"
                                          >
                                            <span className="fas fa-shopping-cart"></span>
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}
