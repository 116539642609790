import Web3 from "web3";
import React from "react";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { TokenController } from "../../core/modules/token";
import { appConfig, AppErrorCode, Contract } from "../../core/app";
import { UniverseNFTController } from "../../core/modules/universeNFT";
import { ApiHelpers } from "../../core/helpers/api";

import Anim1 from "../../assets/images/animations/1.png";
import Anim2 from "../../assets/images/animations/2.jpg";
import Anim3 from "../../assets/images/animations/3.gif";
import Anim4 from "../../assets/images/animations/4.gif";
import Anim5 from "../../assets/images/animations/5.jpg";
import Anim6 from "../../assets/images/animations/6.jpg";
import Anim7 from "../../assets/images/animations/7.png";
import Anim8 from "../../assets/images/animations/8.gif";
import Anim9 from "../../assets/images/animations/9.png";
import Anim10 from "../../assets/images/animations/10.png";
import Anim11 from "../../assets/images/animations/11.png";
import Anim12 from "../../assets/images/animations/12.jpg";
import Anim13 from "../../assets/images/animations/13.png";
import Anim14 from "../../assets/images/animations/14.jpg";
import Anim15 from "../../assets/images/animations/15.png";
import Anim16 from "../../assets/images/animations/16.png";

interface AllCollectionsComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface Collection {
  _address: string;
  _gallery: Array<any>;
  _id: string;
  _image: string;
  _initialPrice: number;
  _maxSupply: number;
  _name: string;
  _owner: string;
  _price: number;
  _symbol: string;
  _time: string;
  _protected: boolean;
  _hide: boolean;
  _official: boolean;
  _alternativeLink: string;
}

interface AllCollectionsComponentState {
  collections: Collection[];
  universalNFT: null | UniverseNFTController;
  lastMintedNFTs: any[];
  shuffleInterval: any;
}

export class AllCollectionsPage extends React.PureComponent<AllCollectionsComponentProps, AllCollectionsComponentState> {
  constructor(props: AllCollectionsComponentProps) {
    super(props);

    this.state = {
      collections: [],
      universalNFT: null,
      shuffleInterval: null,
      lastMintedNFTs: [],
    };
  }

  async componentDidMount() {
    this.loadAndSetLastMinted();
    this.randomizeMintedNFTs();
    this.preloadControllers();
    this.props.onToggleLoader(false);
  }

  async preloadControllers(closeLoader: boolean = false) {
    this.setState({ collections: await ApiHelpers.lastCollections() });
  }

  componentDidUpdate(prevProps: AllCollectionsComponentProps) {
    if (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount) {
      this.preloadControllers();
    }
  }

  private async _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.preloadControllers();
  }

  componentWillUnmount() {
    if (this.state.shuffleInterval) clearInterval(this.state.shuffleInterval);
  }

  async loadAndSetLastMinted() {
    this.setState({
      lastMintedNFTs: [
        Anim1,
        Anim2,
        Anim3,
        Anim4,
        Anim5,
        Anim6,
        Anim7,
        Anim8,
        Anim9,
        Anim10,
        Anim11,
        Anim12,
        Anim13,
        Anim14,
        Anim15,
        Anim16,
        Anim1,
        Anim2,
        Anim3,
        Anim4,
        Anim5,
        Anim6,
        Anim7,
        Anim8,
        Anim9,
        Anim10,
        Anim11,
        Anim12,
        Anim13,
        Anim14,
        Anim15,
        Anim16,
        Anim1,
        Anim2,
        Anim3,
        Anim4,
        Anim5,
        Anim6,
        Anim7,
        Anim8,
        Anim9,
        Anim10,
        Anim11,
        Anim12,
        Anim13,
        Anim14,
        Anim15,
        Anim16,
        Anim1,
        Anim2,
        Anim3,
        Anim4,
        Anim5,
        Anim6,
        Anim7,
        Anim8,
        Anim9,
        Anim10,
        Anim11,
        Anim12,
        Anim13,
        Anim14,
        Anim15,
        Anim16,
        Anim1,
        Anim2,
        Anim3,
        Anim4,
        Anim5,
        Anim6,
        Anim7,
        Anim8,
        Anim9,
        Anim10,
        Anim11,
        Anim12,
        Anim13,
        Anim14,
        Anim15,
        Anim16,
      ],
    });
  }

  randomizeMintedNFTs() {
    const shuffleInterval = setInterval(() => {
      let shuffledArray = [...this.state.lastMintedNFTs];
      let currentIndex = shuffledArray.length;
      let randomIndex = 0;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [shuffledArray[currentIndex], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[currentIndex]];
      }

      this.setState({ lastMintedNFTs: shuffledArray });
    }, 5000);

    this.setState({ shuffleInterval });
  }

  render() {
    return (
      <React.Fragment>
        <div className="ct-my-collections">
          <div className="ct-minted-animations">
            <div className="ct-list">
              {this.state.lastMintedNFTs.map((nft, index) => {
                return (
                  <div key={index} className="ct-nft">
                    <img src={nft} alt="" />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="ct-minted-shadow"></div>
          {/* 
          {this.state.collections.length > 0 ? (
            <React.Fragment>
              <div className="ct-last-collections">
                {[this.state.collections[0], this.state.collections[1]].map((collection) => {
                  console.log(collection);
                  if (collection) {
                    return (
                      <div className="ct-last-collection">
                        <img src={collection._image} alt="" />
                        <div className="ct-info">
                          <h4>{collection._name}</h4>
                        </div>
                      </div>
                    );
                  } else return "";
                })}
              </div>
            </React.Fragment>
          ) : (
            ""
          )} */}

          <div className="ct-max-container ct-collections-list">
            {this.state.collections.length > 0 ? (
              this.state.collections.map((collection: Collection, index: number) => {
                if (collection._hide) {
                  return "";
                } else {
                  return (
                    <div
                      className={
                        "ct-minted ct-minted-collection" +
                        (collection._official ? " ct-official" : collection._protected ? " ct-protected" : " ct-unprotected")
                      }
                      key={index}
                    >
                      <div className="ct-info">
                        <h3>{collection._name}</h3>
                        <a
                          href={collection._alternativeLink ? collection._alternativeLink : "/collection?address=" + collection._address}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          minting page
                        </a>
                      </div>
                      <img src={collection._image} alt="" />
                    </div>
                  );
                }
              })
            ) : (
              <h4>Go to the minting page. You don't have any collection</h4>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
