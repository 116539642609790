export const navigationLinks = [
  {
    display: "NFTs",
    links: [
      {
        href: "/",
        display: "NFT Minting",
        icon: "fas fa-boxes",
        type: "internal",
        message: "Create and mint your NFTs on the meter network.",
        disabled: false,
      },
      {
        href: "/nft-collections",
        display: "Collection Minting",
        icon: "fas fa-boxes",
        type: "internal",
        message: "Create your own NFTs collection using our contract generator",
        disabled: false,
      },
      {
        href: "/nft-bidding",
        display: "NFT Bidding",
        icon: "fas fa-boxes",
        type: "internal",
        message: "Sell or buy NFTs using our bidding system.",
        disabled: false,
      },
      {
        href: "/staking",
        display: "Staking",
        icon: "fas fa-box-open",
        type: "internal",
        message: "Stake everything to earn progressive rewards",
        disabled: false,
      },
    ],
  },
  {
    display: "Launch",
    links: [
      {
        href: "/collections",
        display: "All Collections",
        message: "See all collections launched in our platform.",
        icon: "fas fa-box",
        type: "internal",
        disabled: false,
      },
    ],
  },
  {
    display: "Tokenomics",
    links: [
      {
        href: "/distribution",
        display: "Distribution",
        message: "Distribute all rewards between staking pools and buy and burn systems.",
        icon: "fas fa-dollar-sign",
        type: "internal",
        disabled: false,
      },
    ],
  },
];
